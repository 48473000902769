import { mapActions, mapGetters } from 'vuex'
import { timezone, getDayRange } from '@/utils/day'

import CustomizeDate from '../../../record/CustomizeDate'
import { resStatus } from '@/utils/resUtils'
import Nodata from '@/components/base/Nodata'
import NodataTemplate3 from '@/components/base/Nodata-template3'

export default {
  components: { CustomizeDate, Nodata, NodataTemplate3 },

  data: () => ({
    dateBtn: 0,

    showPickerDate: false,

    form: {
      trans_at: '', // 2020-07-01,2020-07-20	起始時間,結束時間
      timezone: '', // 時區
    },

    record: [],
  }),

  computed: {
    ...mapGetters(['nation', 'theme']),

    /**
     * 快捷日期選擇按鈕
     * @date 2021-05-19
     * @returns {array} 快捷日期選擇按鈕 (index => 日期區間天數)
     */
    dataBtnText() {
      return [
        { 0: this.$t('myCenter.today') },
        { 1: this.$t('myCenter.yesterday') },
        { 6: this.$t('myCenter.Last7days') },
        { 29: this.$t('myCenter.Last30days') },
      ]
    },

    /**
     * 資金明細 項目¸
     * @date 2021-05-19
     * @returns {array} 資金明細 項目組
     */
    recordItem() {
      return [
        // 總存款
        { deposit: this.$t('myCenter.totalDeposit') },
        // 總取款
        { withdraw: this.$t('myCenter.totalWithdraw') },
        // 總投注
        { bet: this.$t('myCenter.totalWager') },
        // 總輸贏
        { winlost: this.$t('myCenter.totalWinloss') },
      ]
    },
  },

  watch: {
    dateBtn(newTab) {
      if (newTab !== 4) this.showPickerDate = false
    },
  },

  mounted() {
    this.form.timezone = timezone()
    this.form.trans_at = getDayRange(0)
    this.getUserWalletRecord(this.form)
  },

  methods: {
    winlostDynamicColor(itemName, theme) {
      if (itemName === 'winlost' && theme === 'dark') return 'secondary--text'
      if (itemName === 'winlost' && theme === 'light') return 'primary--text'
      else return 'title--text'
    },

    ...mapActions(['get_user_wallet_record', 'show_alert']),

    /**
     * 取得資金明細 (成功)
     * @date 2021-05-19
     * @param {any} data
     * @returns {any}
     */
    getUserWalletRecordSuccess(data) {
      this.record = data?.detail
    },

    /**
     * 取得資金明細 (失敗) 超過 30 天日期?
     * @date 2021-05-19
     */
    getUserWalletRecordFail() {
      this.record = []
      this.show_alert({
        icon: 'fail',
      })
    },

    /**
     * 取得資金明細
     * @date 2021-05-19
     * @param {Object} form {trans_at, timezone}
     */
    async getUserWalletRecord(form) {
      const res = await this.get_user_wallet_record(form)
      resStatus(
        res,
        this.getUserWalletRecordSuccess,
        this.getUserWalletRecordFail,
      )
    },

    /**
     * 切換日期按鈕
     * @date 2021-03-17
     * @param {Number} dayNum 日期區間 從今天起算幾天前
     * @param {Number} index 按鍵索引
     */
    changeData(dayNum, index) {
      // 已在當前 tab 不再重複打 api
      if (this.dateBtn === index) return false

      this.dateBtn = index
      this.form.trans_at = getDayRange(dayNum)
      this.getUserWalletRecord(this.form)
    },

    /**
     * 自定義功能
     * @date 2021-05-18
     */
    customDate() {
      if (this.dateBtn === 4) return false

      this.dateBtn = 4
      this.showPickerDate = true

      this.form.trans_at = getDayRange(0)
      this.getUserWalletRecord(this.form)
    },

    /**
     * 日期選擇器選取日期 (傳父)
     * @date 2021-05-19
     * @param {string} date 日期區間
     */
    pickerDateEv(date) {
      this.form.trans_at = date
      this.getUserWalletRecord(this.form)
    },
  },
}
