var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "profile" } },
    [
      _c(
        "v-form",
        {
          ref: "form",
          model: {
            value: _vm.veridate,
            callback: function ($$v) {
              _vm.veridate = $$v
            },
            expression: "veridate",
          },
        },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                { staticClass: "pt-4 pb-3" },
                [
                  _c(
                    "v-col",
                    { staticClass: "px-3 py-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "title--text caption font-weight-medium",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("myCenter.bankAccountName")) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "v-text-field",
                        {
                          staticClass: "mt-2",
                          attrs: {
                            disabled: _vm.disable.username,
                            readonly: _vm.disable.username,
                            placeholder: _vm.$t("form.missName"),
                            rules: [
                              _vm.rules.required.userName,
                              _vm.rules.length.userName,
                            ],
                            dense: "",
                            "single-line": "",
                            outlined: "",
                          },
                          model: {
                            value: _vm.form.username,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "username",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "form.username",
                          },
                        },
                        [
                          _vm.canChangeUsername && _vm.disable.username
                            ? _c(
                                "div",
                                { attrs: { slot: "append" }, slot: "append" },
                                [
                                  _c("Icon", {
                                    staticClass: "primary--text cursor-pointer",
                                    attrs: {
                                      data: require("@icon/edit.svg"),
                                      width: "24px",
                                      height: "24px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.editItem("username")
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "px-3 py-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "title--text caption font-weight-medium",
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("myCenter.gender")) + " ")]
                      ),
                      _c(
                        "v-text-field",
                        {
                          staticClass: "mt-2",
                          attrs: {
                            placeholder: _vm.$t("form.missGender"),
                            value: _vm.genderDisplay,
                            disabled: _vm.disable.gender,
                            rules: [_vm.rules.required.gender],
                            readonly: true,
                            dense: "",
                            "single-line": "",
                            outlined: "",
                          },
                          on: {
                            click: function ($event) {
                              _vm.selectDialog = true
                            },
                          },
                        },
                        [
                          _vm.canChangeGender && _vm.disable.gender
                            ? _c(
                                "div",
                                { attrs: { slot: "append" }, slot: "append" },
                                [
                                  _c("Icon", {
                                    staticClass: "primary--text cursor-pointer",
                                    attrs: {
                                      data: require("@icon/edit.svg"),
                                      width: "24px",
                                      height: "24px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.editItem("gender")
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "px-3 py-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "title--text caption font-weight-medium",
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("myCenter.birthday")) + " "
                          ),
                        ]
                      ),
                      _c(
                        "v-dialog",
                        {
                          ref: "dialogFrom",
                          attrs: { width: "290px" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-text-field",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "mt-2",
                                          attrs: {
                                            disabled: _vm.disable.birthdate,
                                            readonly: true,
                                            placeholder:
                                              _vm.$t("form.missBirthday"),
                                            rules: [
                                              _vm.rules.required.birthdate,
                                            ],
                                            dense: "",
                                            "single-line": "",
                                            outlined: "",
                                          },
                                          model: {
                                            value: _vm.form.birthdate,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "birthdate",
                                                $$v
                                              )
                                            },
                                            expression: "form.birthdate",
                                          },
                                        },
                                        "v-text-field",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _vm.canChangeBirthday &&
                                      _vm.disable.birthdate
                                        ? _c(
                                            "div",
                                            {
                                              attrs: { slot: "append" },
                                              slot: "append",
                                            },
                                            [
                                              _c("Icon", {
                                                staticClass:
                                                  "primary--text cursor-pointer",
                                                attrs: {
                                                  data: require("@icon/edit.svg"),
                                                  width: "24px",
                                                  height: "24px",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.editItem(
                                                      "birthday"
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.datePicker,
                            callback: function ($$v) {
                              _vm.datePicker = $$v
                            },
                            expression: "datePicker",
                          },
                        },
                        [
                          _c(
                            "v-date-picker",
                            {
                              staticClass: "bg",
                              attrs: {
                                max: _vm.legaAge,
                                "header-color": "bg",
                                color: "secondary",
                                scrollable: "",
                              },
                              model: {
                                value: _vm.form.birthdate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "birthdate", $$v)
                                },
                                expression: "form.birthdate",
                              },
                            },
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "subtitle-1 font-weight-medium",
                                  attrs: { text: "", color: "subtitle" },
                                  on: { click: _vm.cancelPickerBirthday },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("global.cancel")) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "subtitle-1 font-weight-medium",
                                  attrs: { text: "", color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      _vm.datePicker = false
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("global.confirmation")) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "px-3 py-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "title--text caption font-weight-medium",
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("myCenter.mobile")) + " ")]
                      ),
                      _c("v-select", {
                        staticClass: "mt-2",
                        attrs: {
                          items: _vm.phoneCountryCodeList,
                          "item-text": "country_label",
                          "item-value": "country_code",
                          label: "Select",
                          dense: "",
                          "single-line": "",
                          outlined: "",
                          disabled: _vm.disable.contact_no,
                        },
                        model: {
                          value: _vm.phoneCountryCode,
                          callback: function ($$v) {
                            _vm.phoneCountryCode = $$v
                          },
                          expression: "phoneCountryCode",
                        },
                      }),
                      _c(
                        "v-text-field",
                        {
                          attrs: {
                            placeholder: _vm.$t("form.missMobile"),
                            disabled: _vm.disable.contact_no,
                            readonly: _vm.disable.contact_no,
                            rules: [_vm.rules.required.mobile],
                            type: "number",
                            dense: "",
                            "single-line": "",
                            outlined: "",
                          },
                          model: {
                            value: _vm.form.contact_no,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "contact_no",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "form.contact_no",
                          },
                        },
                        [
                          _vm.canChangePhoneNumber && _vm.disable.contact_no
                            ? _c(
                                "div",
                                { attrs: { slot: "append" }, slot: "append" },
                                [
                                  _c("Icon", {
                                    staticClass: "primary--text cursor-pointer",
                                    attrs: {
                                      data: require("@icon/edit.svg"),
                                      width: "24px",
                                      height: "24px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.editItem("mobile")
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                  !_vm.disable.contact_no && _vm.validationType === "sms"
                    ? _c(
                        "v-col",
                        { staticClass: "px-3 py-1", attrs: { cols: "12" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-2 pb-2",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "title--text caption font-weight-medium",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("form.missCaptcha")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "py-0 pr-1",
                                  attrs: { cols: "8" },
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      dense: "",
                                      placeholder: _vm.$t(
                                        "form.missVerifyCode"
                                      ),
                                      rules: [
                                        _vm.rules.required.smsCode,
                                        _vm.rules.format.verifyMobile,
                                      ],
                                      "single-line": "",
                                      outlined: "",
                                    },
                                    model: {
                                      value: _vm.mobileVerifyCodeSMS,
                                      callback: function ($$v) {
                                        _vm.mobileVerifyCodeSMS =
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                      },
                                      expression: "mobileVerifyCodeSMS",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "py-0 pl-1",
                                  attrs: { cols: "4" },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "px-0 caption",
                                      attrs: {
                                        disabled: _vm.disable.verifiMobileCode,
                                        block: "",
                                        outlined: "",
                                        color: "primary",
                                      },
                                      on: { click: _vm.verifiMobileCode },
                                    },
                                    [
                                      _vm.verifiMobileCodeBtnSec === 60
                                        ? [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "myCenter.sendVerificationCode"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        : [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.verifiMobileCodeBtnSec +
                                                    "s" +
                                                    _vm.$t("global.retry")
                                                ) +
                                                " "
                                            ),
                                          ],
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isLiffMode
                    ? _c(
                        "v-col",
                        { staticClass: "px-3 py-1", attrs: { cols: "12" } },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "title--text caption font-weight-medium",
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("myCenter.email")) + " "
                              ),
                            ]
                          ),
                          _vm.validationType !== "email"
                            ? _c(
                                "v-text-field",
                                {
                                  staticClass: "mt-2",
                                  attrs: {
                                    disabled: _vm.disable.email,
                                    readonly: _vm.disable.email,
                                    placeholder: _vm.$t("form.missEmail"),
                                    rules: [
                                      _vm.rules.required.mail,
                                      _vm.rules.format.mail,
                                    ],
                                    dense: "",
                                    "single-line": "",
                                    outlined: "",
                                  },
                                  model: {
                                    value: _vm.form.email,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "email",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "form.email",
                                  },
                                },
                                [
                                  _vm.canChangeEmail && _vm.disable.email
                                    ? _c(
                                        "div",
                                        {
                                          attrs: { slot: "append" },
                                          slot: "append",
                                        },
                                        [
                                          _c("Icon", {
                                            staticClass:
                                              "primary--text cursor-pointer",
                                            attrs: {
                                              data: require("@icon/edit.svg"),
                                              width: "24px",
                                              height: "24px",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.editItem("email")
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _c(
                                "v-text-field",
                                {
                                  staticClass: "mt-2",
                                  attrs: {
                                    disabled:
                                      _vm.disable.email &&
                                      _vm.form.email !== "",
                                    placeholder: _vm.$t("form.missEmail"),
                                    rules: [
                                      _vm.rules.required.mail,
                                      _vm.rules.format.mail,
                                    ],
                                    dense: "",
                                    "single-line": "",
                                    outlined: "",
                                    readonly: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.emailDialog.status = true
                                    },
                                  },
                                  model: {
                                    value: _vm.form.email,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "email",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "form.email",
                                  },
                                },
                                [
                                  _vm.canChangeEmail && _vm.disable.email
                                    ? _c(
                                        "div",
                                        {
                                          attrs: { slot: "append" },
                                          slot: "append",
                                        },
                                        [
                                          _c("Icon", {
                                            staticClass:
                                              "primary--text cursor-pointer",
                                            attrs: {
                                              data: require("@icon/edit.svg"),
                                              width: "24px",
                                              height: "24px",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.editItem("email")
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isLiffMode
                    ? _c(
                        "v-col",
                        {
                          staticClass: "px-3 py-0",
                          attrs: { cols: _vm.form.withdrawal_key ? 6 : 12 },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "title--text caption font-weight-medium",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("myCenter.withdrawPassword")) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "v-text-field",
                            {
                              staticClass: "mt-2",
                              attrs: {
                                disabled: _vm.disable.withdrawal_key,
                                readonly: _vm.disable.withdrawal_key,
                                placeholder: _vm.$t(
                                  "form.misswithdrawPassword"
                                ),
                                rules: [
                                  _vm.rules.required.withdrawal_key,
                                  _vm.rules.length.withdrawal_key,
                                ],
                                type: "password",
                                "single-line": "",
                                dense: "",
                                outlined: "",
                              },
                              model: {
                                value: _vm.form.withdrawal_key,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "withdrawal_key",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "form.withdrawal_key",
                              },
                            },
                            [
                              _vm.canChangeWithdrawalPassword &&
                              _vm.disable.withdrawal_key
                                ? _c(
                                    "div",
                                    {
                                      attrs: { slot: "append" },
                                      slot: "append",
                                    },
                                    [
                                      _c("Icon", {
                                        staticClass:
                                          "primary--text cursor-pointer",
                                        attrs: {
                                          data: require("@icon/edit.svg"),
                                          width: "24px",
                                          height: "24px",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.editItem(
                                              "withdrawPassword"
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-col",
                    { staticClass: "pt-0", attrs: { cols: "12" } },
                    [
                      _vm.disable.withdrawal_key
                        ? [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-center caption comment--text",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("myCenter.contactCsInfo")) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        : [
                            _c(
                              "v-btn",
                              {
                                staticClass: "mt-2",
                                attrs: {
                                  block: "",
                                  height: "44",
                                  color: "primary",
                                  disabled: !_vm.veridate,
                                },
                                on: { click: _vm.preSetUserProfile },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "subtitle-1 btnText--text" },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("global.save")) + " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                      _vm.showUserChangePasswordBtn
                        ? [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-center caption comment--text",
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mt-3",
                                    attrs: {
                                      color: "danger",
                                      height: "44",
                                      block: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.userPasswordDialog.status = true
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("form.modifyLoginPassword")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "Select",
            {
              attrs: {
                status: _vm.selectDialog.status,
                height: 188,
                width: 240,
              },
            },
            [
              _c(
                "v-row",
                {
                  staticClass: "p-sticky top-0 bg",
                  attrs: { slot: "title" },
                  slot: "title",
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "text-h6 title--text px-6 py-4 p-sticky",
                      attrs: { cols: "12" },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("global.chooseGender")) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { slot: "list" }, slot: "list" },
                _vm._l(_vm.selectDialog.item, function (item, index) {
                  return _c(
                    "v-col",
                    {
                      key: index,
                      staticClass: "px-6 title--text",
                      class: [
                        { secondary: _vm.form.gender === Object.keys(item)[0] },
                      ],
                      attrs: { cols: "12" },
                      on: {
                        click: function ($event) {
                          _vm.setGender(Object.keys(item)[0], _vm.form)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(Object.values(item)[0]) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "Dialog",
            { attrs: { dialog: _vm.preSaveDialog, width: 280, height: 140 } },
            [
              _c(
                "div",
                { staticClass: "px-6", attrs: { slot: "text" }, slot: "text" },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("myCenter.savePersonalInfo")) + " "
                  ),
                ]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "subtitle--text",
                  attrs: { slot: "btn", text: "" },
                  on: {
                    click: function ($event) {
                      _vm.preSaveDialog = false
                    },
                  },
                  slot: "btn",
                },
                [_vm._v(" " + _vm._s(_vm.$t("global.cancel")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { slot: "btn", text: "", color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.editUserProfile(_vm.form)
                    },
                  },
                  slot: "btn",
                },
                [_vm._v(" " + _vm._s(_vm.$t("global.confirmation")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "Dialog",
        { attrs: { height: 140, dialog: _vm.verifyMobileCodeErrorDialog } },
        [
          _c(
            "div",
            {
              staticClass: "px-6 text--text",
              attrs: { slot: "text" },
              slot: "text",
            },
            [_vm._v(" " + _vm._s(_vm.$t("form.errorCaptcha")) + " ")]
          ),
          _c(
            "v-btn",
            {
              attrs: { slot: "btn", text: "", color: "primary" },
              on: { click: _vm.resetMobileVerfifyStatus },
              slot: "btn",
            },
            [_vm._v(" " + _vm._s(_vm.$t("global.confirmation")) + " ")]
          ),
        ],
        1
      ),
      _c(
        "Dialog",
        { attrs: { dialog: _vm.userPasswordDialog.status } },
        [
          _c(
            "div",
            {
              staticClass: "px-6 subtitle-1 font-weight-bold",
              attrs: { slot: "text" },
              slot: "text",
            },
            [
              _c(
                "v-form",
                {
                  ref: "mdfUserPasswordForm",
                  model: {
                    value: _vm.userPasswordDialog.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.userPasswordDialog.form, "status", $$v)
                    },
                    expression: "userPasswordDialog.form.status",
                  },
                },
                [
                  _c("p", [
                    _vm._v(
                      " " + _vm._s(_vm.$t("form.modifyLoginPassword")) + " "
                    ),
                  ]),
                  _vm.validationType === "email"
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "py-0 mt-2", attrs: { cols: "12" } },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "title--text caption font-weight-medium",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("myCenter.emailCaptcha")) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "v-col",
                            { staticClass: "py-0", attrs: { cols: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  dense: "",
                                  placeholder: _vm.$t("form.missVerifyCode"),
                                  rules: [
                                    _vm.rules.required.smsCode,
                                    _vm.rules.format.verifyMobile,
                                  ],
                                  "single-line": "",
                                  outlined: "",
                                },
                                model: {
                                  value: _vm.emailDialog.form.validateCode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.emailDialog.form,
                                      "validateCode",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "emailDialog.form.validateCode",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "pa-0 pr-4", attrs: { cols: "6" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "px-0 caption",
                                  attrs: {
                                    disabled:
                                      _vm.emailDialog.validate.sendBtnSec !==
                                      60,
                                    block: "",
                                    outlined: "",
                                    color: "primary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.sendEmailValidateCode(
                                        _vm.form,
                                        "edit"
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm.emailDialog.validate.sendBtnSec === 60
                                    ? [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "myCenter.sendVerificationCode"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    : [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.emailDialog.validate
                                                .sendBtnSec +
                                                "s" +
                                                _vm.$t("global.retry")
                                            ) +
                                            " "
                                        ),
                                      ],
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.validationType === "sms"
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "py-0 mt-2", attrs: { cols: "12" } },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "title--text caption font-weight-medium",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("myCenter.mobileCaptcha")) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "v-col",
                            { staticClass: "py-0", attrs: { cols: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  dense: "",
                                  placeholder: _vm.$t("form.missVerifyCode"),
                                  rules: [
                                    _vm.rules.required.smsCode,
                                    _vm.rules.format.verifyMobile,
                                  ],
                                  "single-line": "",
                                  outlined: "",
                                },
                                model: {
                                  value: _vm.mobileVerifyCodeSMS,
                                  callback: function ($$v) {
                                    _vm.mobileVerifyCodeSMS =
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                  },
                                  expression: "mobileVerifyCodeSMS",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "pa-0 pr-4", attrs: { cols: "6" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "px-0 caption",
                                  attrs: {
                                    disabled: _vm.verifiMobileCodeBtnSec !== 60,
                                    block: "",
                                    outlined: "",
                                    color: "primary",
                                  },
                                  on: { click: _vm.verifiMobileCode },
                                },
                                [
                                  _vm.verifiMobileCodeBtnSec === 60
                                    ? [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "myCenter.sendVerificationCode"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    : [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.verifiMobileCodeBtnSec +
                                                "s" +
                                                _vm.$t("global.retry")
                                            ) +
                                            " "
                                        ),
                                      ],
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "input" },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "title--text caption font-weight-medium",
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("form.missPassword")) + " "
                          ),
                        ]
                      ),
                      _c("v-text-field", {
                        staticClass: "mt-2",
                        attrs: {
                          type: "password",
                          placeholder: _vm.$t("form.missPassword"),
                          dense: "",
                          "single-line": "",
                          outlined: "",
                          rules: [_vm.rules.required.withdrawal_key],
                        },
                        model: {
                          value: _vm.userPasswordDialog.form.password,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.userPasswordDialog.form,
                              "password",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "userPasswordDialog.form.password",
                        },
                      }),
                      _c(
                        "span",
                        {
                          staticClass: "title--text caption font-weight-medium",
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("form.newPassword")) + " ")]
                      ),
                      _c("v-text-field", {
                        staticClass: "mt-2",
                        attrs: {
                          type: "password",
                          placeholder: _vm.$t("form.enterNewPassword"),
                          rules: [
                            _vm.rules.required.enterNewPassword,
                            _vm.rules.length.pwd,
                          ],
                          dense: "",
                          "single-line": "",
                          outlined: "",
                        },
                        model: {
                          value: _vm.userPasswordDialog.form.newPassword,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.userPasswordDialog.form,
                              "newPassword",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "userPasswordDialog.form.newPassword",
                        },
                      }),
                      _c(
                        "span",
                        {
                          staticClass: "title--text caption font-weight-medium",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("form.newPasswordConfirm")) +
                              " "
                          ),
                        ]
                      ),
                      _c("v-text-field", {
                        staticClass: "mt-2",
                        attrs: {
                          type: "password",
                          placeholder: _vm.$t("form.enterNewPasswordAgain"),
                          rules: [
                            _vm.rules.required.enterNewPasswordAgain,
                            _vm.rules.format.enterNewPasswordAgain,
                          ],
                          dense: "",
                          "single-line": "",
                          outlined: "",
                        },
                        on: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.userLoginPasswordChange.apply(
                              null,
                              arguments
                            )
                          },
                        },
                        model: {
                          value: _vm.userPasswordDialog.form.newPasswordConfirm,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.userPasswordDialog.form,
                              "newPasswordConfirm",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression:
                            "userPasswordDialog.form.newPasswordConfirm",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "subtitle--text",
              attrs: { slot: "btn", text: "" },
              on: { click: _vm.resetUserLoginPasswordChangeDialog },
              slot: "btn",
            },
            [_vm._v(" " + _vm._s(_vm.$t("global.cancel")) + " ")]
          ),
          _c(
            "v-btn",
            {
              attrs: { slot: "btn", text: "", color: "primary" },
              on: { click: _vm.userLoginPasswordChange },
              slot: "btn",
            },
            [_vm._v(" " + _vm._s(_vm.$t("global.confirmation")) + " ")]
          ),
        ],
        1
      ),
      _c(
        "Dialog",
        { attrs: { dialog: _vm.emailDialog.status } },
        [
          _c(
            "div",
            {
              staticClass: "px-6 subtitle-1 font-weight-bold",
              attrs: { slot: "text" },
              slot: "text",
            },
            [
              _c("p", [_vm._v(_vm._s(_vm.emailDialog.title))]),
              _c(
                "v-form",
                {
                  ref: "emailDialogForm",
                  model: {
                    value: _vm.emailDialog.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.emailDialog.form, "status", $$v)
                    },
                    expression: "emailDialog.form.status",
                  },
                },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0 mt-2", attrs: { cols: "12" } },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "title--text caption font-weight-medium",
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("myCenter.email")) + " "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "8" } },
                        [
                          _c("v-text-field", {
                            ref: "emailInput",
                            attrs: {
                              type: "email",
                              placeholder: _vm.$t("form.missEmail"),
                              rules: [
                                _vm.rules.required.mail,
                                _vm.rules.format.mail,
                              ],
                              dense: "",
                              "single-line": "",
                              outlined: "",
                            },
                            model: {
                              value: _vm.emailDialog.form.email,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.emailDialog.form,
                                  "email",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "emailDialog.form.email",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pa-0 pr-4", attrs: { cols: "4" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "px-0 caption",
                              attrs: {
                                disabled:
                                  _vm.emailDialog.validate.sendBtnSec !== 60,
                                block: "",
                                outlined: "",
                                color: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.sendEmailValidateCode(
                                    _vm.emailDialog.form
                                  )
                                },
                              },
                            },
                            [
                              _vm.emailDialog.validate.sendBtnSec === 60
                                ? [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "myCenter.sendVerificationCode"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                : [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.emailDialog.validate.sendBtnSec +
                                            "s" +
                                            _vm.$t("global.retry")
                                        ) +
                                        " "
                                    ),
                                  ],
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "12" } },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "title--text caption font-weight-medium",
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("form.missCaptcha")) + " "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              dense: "",
                              placeholder: _vm.$t("form.missVerifyCode"),
                              rules: [
                                _vm.rules.required.smsCode,
                                _vm.rules.format.verifyMobile,
                              ],
                              "single-line": "",
                              outlined: "",
                            },
                            model: {
                              value: _vm.emailDialog.form.validateCode,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.emailDialog.form,
                                  "validateCode",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "emailDialog.form.validateCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "subtitle--text",
              attrs: { slot: "btn", text: "" },
              on: { click: _vm.emailDialogClose },
              slot: "btn",
            },
            [_vm._v(" " + _vm._s(_vm.$t("global.cancel")) + " ")]
          ),
          _c(
            "v-btn",
            {
              attrs: { slot: "btn", text: "", color: "primary" },
              on: {
                click: function ($event) {
                  return _vm.emailDialogConfirm(_vm.emailDialog.form)
                },
              },
              slot: "btn",
            },
            [_vm._v(" " + _vm._s(_vm.$t("global.confirmation")) + " ")]
          ),
        ],
        1
      ),
      _c(
        "Dialog",
        { attrs: { dialog: _vm.edit.dialogStatus } },
        [
          _c(
            "div",
            {
              staticClass: "px-6 subtitle-1 font-weight-bold",
              attrs: { slot: "text" },
              slot: "text",
            },
            [
              _c(
                "v-form",
                {
                  ref: "editForm",
                  model: {
                    value: _vm.edit.validate,
                    callback: function ($$v) {
                      _vm.$set(_vm.edit, "validate", $$v)
                    },
                    expression: "edit.validate",
                  },
                },
                [
                  _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("global.edit") + _vm.edit.dialogTitle) +
                        " "
                    ),
                  ]),
                  (_vm.validationType === "email" &&
                    _vm.edit.type !== "email") ||
                  (_vm.edit.type === "mobile" && _vm.validationType !== "none")
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "py-0 mt-2", attrs: { cols: "12" } },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "title--text caption font-weight-medium",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("myCenter.emailCaptcha")) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "v-col",
                            { staticClass: "py-0", attrs: { cols: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  dense: "",
                                  placeholder: _vm.$t("form.missVerifyCode"),
                                  rules: [
                                    _vm.rules.required.smsCode,
                                    _vm.rules.format.verifyMobile,
                                  ],
                                  "single-line": "",
                                  outlined: "",
                                },
                                model: {
                                  value: _vm.emailDialog.form.validateCode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.emailDialog.form,
                                      "validateCode",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "emailDialog.form.validateCode",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "pa-0 pr-4", attrs: { cols: "6" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "px-0 caption",
                                  attrs: {
                                    disabled:
                                      _vm.emailDialog.validate.sendBtnSec !==
                                      60,
                                    block: "",
                                    outlined: "",
                                    color: "primary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.sendEmailValidateCode(
                                        _vm.emailDialog.form,
                                        "edit"
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm.emailDialog.validate.sendBtnSec === 60
                                    ? [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "myCenter.sendVerificationCode"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    : [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.emailDialog.validate
                                                .sendBtnSec +
                                                "s" +
                                                _vm.$t("global.retry")
                                            ) +
                                            " "
                                        ),
                                      ],
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  (_vm.validationType === "sms" &&
                    _vm.edit.type !== "mobile") ||
                  (_vm.edit.type === "email" && _vm.validationType !== "none")
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "py-0 mt-2", attrs: { cols: "12" } },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "title--text caption font-weight-medium",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("myCenter.mobileCaptcha")) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "v-col",
                            { staticClass: "py-0", attrs: { cols: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  dense: "",
                                  placeholder: _vm.$t("form.missVerifyCode"),
                                  rules: [
                                    _vm.rules.required.smsCode,
                                    _vm.rules.format.verifyMobile,
                                  ],
                                  "single-line": "",
                                  outlined: "",
                                },
                                model: {
                                  value: _vm.mobileVerifyCodeSMS,
                                  callback: function ($$v) {
                                    _vm.mobileVerifyCodeSMS =
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                  },
                                  expression: "mobileVerifyCodeSMS",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "pa-0 pr-4", attrs: { cols: "6" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "px-0 caption",
                                  attrs: {
                                    disabled: _vm.verifiMobileCodeBtnSec !== 60,
                                    block: "",
                                    outlined: "",
                                    color: "primary",
                                  },
                                  on: { click: _vm.verifiMobileCode },
                                },
                                [
                                  _vm.verifiMobileCodeBtnSec === 60
                                    ? [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "myCenter.sendVerificationCode"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    : [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.verifiMobileCodeBtnSec +
                                                "s" +
                                                _vm.$t("global.retry")
                                            ) +
                                            " "
                                        ),
                                      ],
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.edit.type === "mobile"
                    ? [
                        _c(
                          "div",
                          { staticClass: "mt-2" },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "title--text caption font-weight-medium",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("myCenter.newMobile")) +
                                    " "
                                ),
                              ]
                            ),
                            _c("v-text-field", {
                              staticClass: "mt-2",
                              attrs: {
                                placeholder: _vm.$t("form.missMobile"),
                                dense: "",
                                "single-line": "",
                                outlined: "",
                                rules: [_vm.rules.required.mobile],
                              },
                              model: {
                                value: _vm.edit.form.contact_no,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.edit.form,
                                    "contact_no",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "edit.form.contact_no",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _vm.edit.type === "email"
                    ? [
                        _c(
                          "div",
                          { staticClass: "mt-2" },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "title--text caption font-weight-medium",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("global.editNew") +
                                        _vm.$t("myCenter.email")
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c("v-text-field", {
                              staticClass: "mt-2",
                              attrs: {
                                placeholder: _vm.$t("form.missEmail"),
                                dense: "",
                                "single-line": "",
                                outlined: "",
                                rules: [
                                  _vm.rules.required.mail,
                                  _vm.rules.format.mail,
                                ],
                              },
                              model: {
                                value: _vm.edit.form.email,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.edit.form,
                                    "email",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "edit.form.email",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _vm.edit.type === "username"
                    ? [
                        _c(
                          "div",
                          { staticClass: "mt-2" },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "title--text caption font-weight-medium",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("global.editNew") +
                                        _vm.$t("myCenter.bankAccountName")
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c("v-text-field", {
                              staticClass: "mt-2",
                              attrs: {
                                placeholder: _vm.$t("form.missName"),
                                dense: "",
                                "single-line": "",
                                outlined: "",
                                rules: [
                                  _vm.rules.required.userName,
                                  _vm.rules.length.userName,
                                ],
                              },
                              model: {
                                value: _vm.edit.form.username,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.edit.form,
                                    "username",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "edit.form.username",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _vm.edit.type === "gender"
                    ? [
                        _c(
                          "div",
                          { staticClass: "mt-2" },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "title--text caption font-weight-medium",
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("myCenter.gender")) + " "
                                ),
                              ]
                            ),
                            _c("v-select", {
                              attrs: {
                                items: _vm.genderItems,
                                "item-text": "gender",
                                "item-value": "value",
                                dense: "",
                                outlined: "",
                              },
                              model: {
                                value: _vm.edit.form.gender,
                                callback: function ($$v) {
                                  _vm.$set(_vm.edit.form, "gender", $$v)
                                },
                                expression: "edit.form.gender",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _vm.edit.type === "birthday"
                    ? [
                        _c(
                          "div",
                          { staticClass: "mt-2" },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "title--text caption font-weight-medium",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("myCenter.birthday")) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0 px-0",
                                attrs: { cols: "12" },
                              },
                              [
                                _c(
                                  "v-dialog",
                                  {
                                    ref: "dialogFrom",
                                    attrs: { persistent: "", width: "290px" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-text-field",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass: "mt-2",
                                                      attrs: {
                                                        placeholder:
                                                          _vm.$t(
                                                            "form.missBirthday"
                                                          ),
                                                        rules: [
                                                          _vm.rules.required
                                                            .birthdate,
                                                        ],
                                                        dense: "",
                                                        "single-line": "",
                                                        outlined: "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.edit.form
                                                            .birthdate,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.edit.form,
                                                            "birthdate",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "edit.form.birthdate",
                                                      },
                                                    },
                                                    "v-text-field",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                )
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1146112403
                                    ),
                                    model: {
                                      value: _vm.datePicker,
                                      callback: function ($$v) {
                                        _vm.datePicker = $$v
                                      },
                                      expression: "datePicker",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-date-picker",
                                      {
                                        staticClass: "bg",
                                        attrs: {
                                          max: _vm.legaAge,
                                          "header-color": "bg",
                                          color: "secondary",
                                          scrollable: "",
                                        },
                                        model: {
                                          value: _vm.edit.form.birthdate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.edit.form,
                                              "birthdate",
                                              $$v
                                            )
                                          },
                                          expression: "edit.form.birthdate",
                                        },
                                      },
                                      [
                                        _c("v-spacer"),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass:
                                              "subtitle-1 font-weight-medium",
                                            attrs: {
                                              text: "",
                                              color: "subtitle",
                                            },
                                            on: {
                                              click: _vm.chancelEditPickerDate,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("global.cancel")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass:
                                              "subtitle-1 font-weight-medium",
                                            attrs: {
                                              text: "",
                                              color: "primary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.datePicker = false
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("global.confirmation")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _vm.edit.type === "withdrawPassword"
                    ? _c(
                        "div",
                        { staticClass: "input" },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "title--text caption font-weight-medium",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("myCenter.newWithdrawPassword")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c("v-text-field", {
                            staticClass: "mt-2",
                            attrs: {
                              type: "password",
                              placeholder: _vm.$t(
                                "myCenter.newWithdrawPassword"
                              ),
                              rules: [
                                _vm.rules.required.enterNewPassword,
                                _vm.rules.length.pwd,
                              ],
                              dense: "",
                              "single-line": "",
                              outlined: "",
                            },
                            model: {
                              value: _vm.edit.form.withdrawal_key,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.edit.form,
                                  "withdrawal_key",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "edit.form.withdrawal_key",
                            },
                          }),
                          _c(
                            "span",
                            {
                              staticClass:
                                "title--text caption font-weight-medium",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "myCenter.newWithdrawPasswordConfirm"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c("v-text-field", {
                            staticClass: "mt-2",
                            attrs: {
                              type: "password",
                              placeholder: _vm.$t(
                                "myCenter.newWithdrawPasswordConfirm"
                              ),
                              rules: [
                                _vm.rules.required.enterNewPasswordAgain,
                                _vm.rules.format.enterNewWithdrawKeyAgain,
                              ],
                              dense: "",
                              "single-line": "",
                              outlined: "",
                            },
                            on: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.userLoginPasswordChange.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                            model: {
                              value: _vm.edit.check_withdrawal_key,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.edit,
                                  "check_withdrawal_key",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "edit.check_withdrawal_key",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "subtitle--text",
              attrs: { slot: "btn", text: "" },
              on: { click: _vm.chancelEditDialog },
              slot: "btn",
            },
            [_vm._v(" " + _vm._s(_vm.$t("global.cancel")) + " ")]
          ),
          _c(
            "v-btn",
            {
              attrs: { slot: "btn", text: "", color: "primary" },
              on: {
                click: function ($event) {
                  return _vm.editUserProfileItem(_vm.edit.form)
                },
              },
              slot: "btn",
            },
            [_vm._v(" " + _vm._s(_vm.$t("global.confirmation")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }