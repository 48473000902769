<template>
  <div
    id="profile"
    class="pt-56 h-100dvh over-flow-y-scroll"
  >
    <div
      class="p-sticky bg z-index-1"
      :class="[{ 'px-4': layout !== 'template1' }]"
      :style="{ top: '0px'}"
    >
      <!-- TABS -->
      <v-tabs
        v-model="activeTab"
        height="40"
        background-color="transparent"
        :color="theme === 'dark' ? 'secondary' : 'primary'"
        :grow="layout === 'template1'"
      >
        <v-tab
          v-for="(tab, index) in tabs"
          :key="index"
          class="subtitle-1 font-weight-medium"
        >
          {{ tab }}
        </v-tab>
      </v-tabs>
      <v-divider class="divider" />
    </div>

    <!-- tab 內容 -->
    <v-container>
      <v-row class="px-1">
        <v-col
          cols="12"
          class="pa-0"
        >
          <component :is="activeContent" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Profile from './Profile'
import Record from './Record'

export default {
  components: {
    Profile,
    Record,
  },

  data: () => ({
    activeTab: 0,
  }),

  computed: {
    ...mapGetters(['layout', 'theme', 'isLiffMode']),

    defaultTemplate() {
      const defaultTemplate = ['template1', 'template2']
      if (defaultTemplate.includes(this.layout)) return 'template1'
      return this.layout
    },

    tabs() {
      if (this.isLiffMode) return [this.$t('myCenter.info')]
      return [this.$t('myCenter.info'), this.$t('myCenter.funding')]
    },

    activeContent() {
      return this.activeTab === 0 ? 'Profile' : 'Record'
    },
  },

  mounted() {
    this.set_toolbar_title(this.$t('myCenter.personalInformation'))
  },

  methods: {
    ...mapActions(['set_toolbar_title']),
  },
}
</script>

<style lang="scss" scoped></style>
