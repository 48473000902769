<template>
  <div id="profile">
    <v-form
      ref="form"
      v-model="veridate"
    >
      <v-container>
        <v-row class="pt-4 pb-3 px-3">
          <!-- 開戶姓名 -->
          <v-text-field
            v-model.trim="form.username"
            :disabled="disable.username"
            :readonly="disable.username"
            :placeholder="$t('form.missName')"
            :rules="[rules.required.userName, rules.length.userName]"
            class="mt-4"
            single-line
            outlined
            :background-color="theme === 'light' ? 'white' : 'black'"
          >
            <div
              slot="prepend-inner"
              class="mt-1 px-2 comment--text"
            >
              {{ $t("myCenter.bankAccountName") }}
            </div>

            <div
              v-if="canChangeUsername && disable.username"
              slot="append"
            >
              <span
                class="caption cursor-pointer"
                :class="theme === 'dark' ? 'secondary--text' : 'primary--text'"
                @click="editItem('username')"
              >
                {{ $t('global.edit') }}
              </span>
            </div>
          </v-text-field>

          <!-- 性別 -->
          <div
            class="gender-input px-3 w-100 rounded d-flex align-center"
            :style="{ border: `1px solid ${ theme === 'dark' ? '#2d2d2d' : '#bbb' }`, background: theme === 'dark' ? 'black' : 'white' }"
            @click="settingGender"
          >
            <div class="comment--text ml-1">
              {{ $t("myCenter.gender") }}
            </div>
            <v-spacer />
            <v-radio-group
              v-model="form.gender"
              readonly
              row
              dense
            >
              <v-radio
                v-for="(item, index) in genderAry"
                :key="index"
                :label="item"
                :value="index"
                class="caption"
              />
            </v-radio-group>

            <div
              v-if="canChangeGender && disable.gender"
              class="caption cursor-pointer"
              :class="theme === 'dark' ? 'secondary--text' : 'primary--text'"
            >
              <span
                @click="editItem('gender')"
              >
                {{ $t('global.edit') }}
              </span>
            </div>
          </div>

          <v-text-field
            :placeholder="$t('form.missGender')"
            :value="genderDisplay"
            :disabled="disable.gender"
            :rules="[rules.required.gender]"
            class="mt-2 d-none"
            :readonly="true"
          >
            <div
              v-if="canChangeGender && disable.gender"
              slot="append"
            >
              <Icon
                data="@icon/edit.svg"
                width="24px"
                height="24px"
                class="primary--text cursor-pointer"
                @click="editItem('gender')"
              />
            </div>
          </v-text-field>

          <!-- 出生日期 -->
          <v-col
            cols="12"
            class="pa-0"
          >
            <!-- 日期選擇器彈窗 -->
            <v-dialog
              ref="dialogFrom"
              v-model="datePicker"
              width="290px"
            >
              <!-- INPUT -->
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="form.birthdate"
                  :disabled="disable.birthdate"
                  :readonly="true"
                  :placeholder="$t('form.missBirthday')"
                  class="mt-4"
                  single-line
                  outlined
                  v-bind="attrs"
                  :background-color="theme === 'light' ? 'white' : 'black'"
                  v-on="on"
                >
                  <div
                    slot="prepend-inner"
                    class="mt-1 px-2 comment--text"
                  >
                    {{ $t("myCenter.birthday") }}
                  </div>

                  <div
                    v-if="canChangeBirthday && disable.birthdate"
                    slot="append"
                  >
                    <span
                      class="caption cursor-pointer"
                      :class="theme === 'dark' ? 'secondary--text' : 'primary--text'"
                      @click="editItem('birthday')"
                    >
                      {{ $t('global.edit') }}
                    </span>
                  </div>
                </v-text-field>
              </template>

              <!-- 日期選擇器 -->
              <v-date-picker
                v-model="form.birthdate"
                class="bg"
                :max="legaAge"
                header-color="bg"
                color="primary"
                scrollable
              >
                <v-spacer />
                <v-btn
                  text
                  color="subtitle"
                  class="subtitle-1 font-weight-medium"
                  @click="cancelPickerBirthday"
                >
                  {{ $t("global.cancel") }}
                </v-btn>

                <v-btn
                  class="subtitle-1 font-weight-medium"
                  text
                  color="primary"
                  @click="datePicker = false"
                >
                  {{ $t("global.confirmation") }}
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>

          <!-- 手機國碼 -->
          <v-col
            cols="12"
            class="px-0 py-0"
          >
            <v-select
              v-model="phoneCountryCode"
              :items="phoneCountryCodeList"
              item-text="country_label"
              item-value="country_code"
              label="Select"
              class="mt-2"
              single-line
              outlined
              :disabled="disable.contact_no"
            />
          </v-col>

          <!-- 手机号码 -->
          <v-col
            cols="12"
            class="px-0 py-0"
          >
            <v-text-field
              v-model.trim="form.contact_no"
              :placeholder="$t('form.missMobile')"
              :disabled="disable.contact_no"
              :readonly="disable.contact_no"
              :rules="[
                rules.required.mobile
              ]"
              type="number"
              single-line
              outlined
              :background-color="theme === 'light' ? 'white' : 'black'"
            >
              <div
                slot="prepend-inner"
                class="mt-1 px-2 comment--text"
              >
                {{ $t("myCenter.mobile") }}
              </div>

              <div
                v-if="canChangePhoneNumber && disable.contact_no"
                slot="append"
              >
                <span
                  class="caption cursor-pointer"
                  :class="theme === 'dark' ? 'secondary--text' : 'primary--text'"
                  @click="editItem('mobile')"
                >
                  {{ $t('global.edit') }}
                </span>
              </div>
            </v-text-field>
          </v-col>

          <!-- 验证码 -->
          <v-col
            cols="12"
            class="pa-0"
          >
            <v-text-field
              v-if="!disable.contact_no && validationType === 'sms'"
              v-model.trim="mobileVerifyCodeSMS"
              :placeholder="$t('form.missVerifyCode')"
              :rules="[rules.required.smsCode, rules.format.verifyMobile]"
              single-line
              outlined
              :background-color="theme === 'light' ? 'white' : 'black'"
            >
              <div
                slot="prepend-inner"
                class="mt-1 px-2 comment--text"
              >
                {{ $t("form.missCaptcha") }}
              </div>

              <div
                slot="append"
              >
                <span
                  :disabled="disable.verifiMobileCode"
                  block
                  outlined
                  class="px-0 caption"
                  :class="theme === 'dark' ? 'secondary--text' : 'primary--text'"
                  @click="verifiMobileCode"
                >
                  <!-- 發送驗證碼 -->
                  <template v-if="verifiMobileCodeBtnSec === 60">
                    {{ $t("myCenter.sendVerificationCode") }}
                  </template>

                  <!-- 59后重試 -->
                  <template v-else>
                    {{ verifiMobileCodeBtnSec + "s" + $t("global.retry") }}
                  </template>
                </span>
              </div>
            </v-text-field>
          </v-col>

          <!-- 电子邮箱 -->
          <v-col
            cols="12"
            class="pa-0"
          >
            <!-- 不需要驗証 (电子邮箱) -->
            <v-text-field
              v-if="validationType !== 'email'"
              v-model.trim="form.email"
              :disabled="disable.email"
              :readonly="disable.email"
              :placeholder="$t('form.missEmail')"
              :rules="[rules.required.mail, rules.format.mail]"
              :background-color="theme === 'light' ? 'white' : 'black'"
              class="mt-2"
              single-line
              outlined
            >
              <div
                slot="prepend-inner"
                class="mt-1 px-2 comment--text"
              >
                {{ $t("myCenter.email") }}
              </div>
              <div
                v-if="canChangeEmail && disable.email"
                slot="append"
              >
                <span
                  class="caption cursor-pointer"
                  :class="theme === 'dark' ? 'secondary--text' : 'primary--text'"
                  @click="editItem('email')"
                >
                  {{ $t('global.edit') }}
                </span>
              </div>
            </v-text-field>

            <!-- 需要驗証 (电子邮箱) -->
            <v-text-field
              v-else
              v-model.trim="form.email"
              :disabled="disable.email && form.email !== ''"
              :placeholder="$t('form.missEmail') "
              :rules="[rules.required.mail, rules.format.mail]"
              class="mt-2"
              single-line
              outlined
              readonly
              :background-color="theme === 'light' ? 'white' : 'black'"
              @click="emailDialog.status = true"
            >
              <div
                slot="prepend-inner"
                class="mt-1 px-2 comment--text"
              >
                {{ $t("myCenter.email") }}
              </div>
              <div
                v-if="canChangeEmail && disable.email"
                slot="append"
              >
                <span
                  class="caption cursor-pointer"
                  :class="theme === 'dark' ? 'secondary--text' : 'primary--text'"
                  @click="editItem('email')"
                >
                  {{ $t('global.edit') }}
                </span>
              </div>
            </v-text-field>
          </v-col>

          <!-- 取款密码 -->
          <v-col
            :cols="12"
            class="px-0 py-0"
          >
            <v-text-field
              v-model.trim="form.withdrawal_key"
              :disabled="disable.withdrawal_key"
              :readonly="disable.withdrawal_key"
              :placeholder="$t('form.misswithdrawPassword')"
              :rules="[
                rules.required.withdrawal_key,
                rules.length.withdrawal_key,
              ]"
              class="mt-2"
              type="password"
              single-line
              outlined
              :background-color="theme === 'light' ? 'white' : 'black'"
            >
              <div
                slot="prepend-inner"
                class="mt-1 px-2 comment--text"
              >
                {{ $t("myCenter.withdrawPassword") }}
              </div>
              <div
                v-if="canChangeWithdrawalPassword && disable.withdrawal_key"
                slot="append"
              >
                <span
                  class="caption cursor-pointer"
                  :class="theme === 'dark' ? 'secondary--text' : 'primary--text'"
                  @click="editItem('withdrawPassword')"
                >
                  {{ $t('global.edit') }}
                </span>
              </div>
            </v-text-field>
          </v-col>

          <!-- 說明文字 && 「保存」 && 「送出」 -->
          <v-col
            cols="12"
            class="pt-0 px-0"
          >
            <!-- 已設定過 說明文字 -->
            <template v-if="disable.withdrawal_key">
              <div class="text-center caption comment--text">
                {{ $t("myCenter.contactCsInfo") }}
              </div>
            </template>

            <!-- 未設定 保存按鈕  :disabled="!veridate"-->
            <template v-else>
              <v-btn
                class="mt-2"
                block
                height="44"
                color="primary"
                :disabled="!veridate"
                @click="preSetUserProfile"
              >
                <span class="subtitle-1 white--text">
                  {{ $t("global.save") }}
                </span>
              </v-btn>
            </template>

            <!-- 使用者修改登入密碼-->
            <template v-if="showUserChangePasswordBtn">
              <div class="text-center caption comment--text">
                <v-btn
                  class="mt-3 white--text"
                  color="danger"
                  height="44"
                  block
                  @click="userPasswordDialog.status = true"
                >
                  {{ $t("form.modifyLoginPassword") }}
                </v-btn>
              </div>
            </template>
          </v-col>
        </v-row>
      </v-container>

      <!-- DIALOG 選擇性別 -->
      <Select
        :status="selectDialog.status"
        :height="188"
        :width="240"
      >
        <v-row
          slot="title"
          class="p-sticky top-0 bg"
        >
          <!-- 選擇性別 -->
          <v-col
            cols="12"
            class="text-h6 title--text px-6 py-4 p-sticky"
          >
            {{ $t("global.chooseGender") }}
          </v-col>
        </v-row>

        <v-row slot="list">
          <v-col
            v-for="(item, index) in selectDialog.item"
            :key="index"
            cols="12"
            class="px-6 title--text"
            :class="[{ secondary: form.gender === Object.keys(item)[0] }]"
            @click="setGender(Object.keys(item)[0], form)"
          >
            {{ Object.values(item)[0] }}
          </v-col>
        </v-row>
      </Select>

      <!-- 保存前 提醒彈窗 -->
      <Dialog
        :dialog="preSaveDialog"
        :width="280"
        :height="140"
      >
        <div
          slot="text"
          class="px-6"
        >
          {{ $t("myCenter.savePersonalInfo") }}
        </div>

        <!-- 取消 -->
        <v-btn
          slot="btn"
          text
          class="subtitle--text"
          @click="preSaveDialog = false"
        >
          {{ $t("global.cancel") }}
        </v-btn>

        <!-- 保存 發送表單 -->
        <v-btn
          slot="btn"
          text
          color="primary"
          @click="editUserProfile(form)"
        >
          {{ $t("global.confirmation") }}
        </v-btn>
      </Dialog>
    </v-form>

    <!-- 手機驗証碼 錯誤 彈窗 -->
    <DialogTemplate3
      :height="140"
      :dialog="verifyMobileCodeErrorDialog"
    >
      <div
        slot="text"
        class="px-6 text--text"
      >
        {{ $t("form.errorCaptcha") }}
      </div>

      <v-btn
        slot="btn"
        text
        color="primary"
        @click="resetMobileVerfifyStatus"
      >
        {{ $t("global.confirmation") }}
      </v-btn>
    </DialogTemplate3>

    <!-- 修改登入密碼 dialog -->
    <DialogTemplate3 :dialog="userPasswordDialog.status">
      <div
        slot="text"
        class="subtitle-1 font-weight-bold"
      >
        <!-- TITLE -->
        <p class="primary text-center py-2 white--text">
          {{ $t("form.modifyLoginPassword") }}
        </p>
        <v-form
          ref="mdfUserPasswordForm"
          v-model="userPasswordDialog.form.status"
          class="px-6 "
        >
          <!-- 驗証 信箱 === 'email' -->
          <v-row v-if="validationType === 'email'">
            <v-col
              cols="12"
              class="mt-2 pb-0"
            >
              <v-text-field
                v-model.trim="emailDialog.form.validateCode"
                :label="$t('myCenter.emailCaptcha')"
                outlined
                :color="theme === 'dark' ? 'secondary' : 'primary'"
                :rules="[rules.required.smsCode, rules.format.verifyMobile]"
              >
                <div
                  slot="append"
                >
                  <span
                    :disabled="emailDialog.validate.sendBtnSec !== 60"
                    block
                    outlined
                    color="primary"
                    class="px-0 caption cursor-pointer"
                    :class="theme === 'dark' ? 'secondary--text' : 'primary--text'"
                    @click="sendEmailValidateCode(form, 'edit')"
                  >
                    <!-- 發送驗證碼 -->
                    <template v-if="emailDialog.validate.sendBtnSec === 60">
                      {{ $t("myCenter.sendVerificationCode") }}
                    </template>

                    <!-- 59后重試 -->
                    <template v-else>
                      {{
                        emailDialog.validate.sendBtnSec + "s" + $t("global.retry")
                      }}
                    </template>
                  </span>
                </div>
              </v-text-field>
            </v-col>
          </v-row>

          <!-- 驗証 手機 === 'sms' -->
          <v-row
            v-if="validationType === 'sms'"
          >
            <v-col cols="12">
              <!-- 手機驗証碼 -->
              <v-text-field
                v-model.trim="mobileVerifyCodeSMS"
                :label="$t('myCenter.mobileCaptcha')"
                :placeholder="$t('form.missVerifyCode')"
                :rules="[rules.required.smsCode, rules.format.verifyMobile]"
                outlined
              >
                <div
                  slot="append"
                >
                  <!-- 手機發送驗証碼 -->
                  <span
                    :disabled="verifiMobileCodeBtnSec !== 60"
                    block
                    outlined
                    class="px-0 caption"
                    :class="theme === 'dark' ? 'secondary--text' : 'primary--text'"
                    @click="verifiMobileCode"
                  >
                    <!-- 發送驗證碼 -->
                    <template v-if="verifiMobileCodeBtnSec === 60">
                      {{ $t("myCenter.sendVerificationCode") }}
                    </template>

                    <!-- 59后重試 -->
                    <template v-else>
                      {{ verifiMobileCodeBtnSec + "s" + $t("global.retry") }}
                    </template>
                  </span>
                </div>
              </v-text-field>
            </v-col>
          </v-row>

          <!-- 輸入登入密碼 -->
          <div class="input">
            <!-- 原有密碼 -->
            <v-text-field
              v-model.trim="userPasswordDialog.form.password"
              type="password"
              :label="$t('form.missPassword')"
              :placeholder="$t('form.missPassword')"
              class="mt-4"
              outlined
              :rules="[rules.required.withdrawal_key]"
            />

            <!-- 新密碼 -->
            <v-text-field
              v-model.trim="userPasswordDialog.form.newPassword"
              type="password"
              :label="$t('form.newPassword')"
              :placeholder="$t('form.enterNewPassword')"
              :rules="[rules.required.enterNewPassword, rules.length.pwd]"
              class="mt-2"
              outlined
            />

            <!-- 再次輸入新密碼 -->
            <v-text-field
              v-model.trim="userPasswordDialog.form.newPasswordConfirm"
              type="password"
              :label="$t('form.newPasswordConfirm')"
              :placeholder="$t('form.enterNewPasswordAgain')"
              :rules="[
                rules.required.enterNewPasswordAgain,
                rules.format.enterNewPasswordAgain,
              ]"
              class="mt-2"
              outlined
              @keyup.enter="userLoginPasswordChange"
            />
          </div>
        </v-form>
      </div>

      <!-- 取消 -->
      <v-btn
        slot="btn"
        text
        class="subtitle--text"
        @click="resetUserLoginPasswordChangeDialog"
      >
        {{ $t("global.cancel") }}
      </v-btn>

      <v-btn
        slot="btn"
        text
        color="primary"
        @click="userLoginPasswordChange"
      >
        {{ $t("global.confirmation") }}
      </v-btn>
    </DialogTemplate3>

    <!-- EMAIL 驗証 -->
    <DialogTemplate3 :dialog="emailDialog.status">
      <div
        slot="text"
        class="px-6 subtitle-1 font-weight-bold"
      >
        <p>{{ emailDialog.title }}</p>
        <v-form
          ref="emailDialogForm"
          v-model="emailDialog.form.status"
        >
          <v-row>
            <v-col
              cols="12"
              class="py-0 mt-2"
            >
              <!-- 新email -->
              <span class="title--text caption font-weight-medium">
                {{ $t("myCenter.email") }}
              </span>
            </v-col>
            <v-col
              cols="8"
              class="py-0"
            >
              <v-text-field
                ref="emailInput"
                v-model.trim="emailDialog.form.email"
                type="email"
                :placeholder="$t('form.missEmail')"
                :rules="[rules.required.mail, rules.format.mail]"
                dense
                single-line
                outlined
              />
            </v-col>

            <v-col
              cols="4"
              class="pa-0 pr-4"
            >
              <v-btn
                :disabled="emailDialog.validate.sendBtnSec !== 60"
                block
                outlined
                color="primary"
                class="px-0 caption"
                @click="sendEmailValidateCode(emailDialog.form)"
              >
                <!-- 發送驗證碼 -->
                <template v-if="emailDialog.validate.sendBtnSec === 60">
                  {{ $t("myCenter.sendVerificationCode") }}
                </template>

                <!-- 59后重試 -->
                <template v-else>
                  {{
                    emailDialog.validate.sendBtnSec + "s" + $t("global.retry")
                  }}
                </template>
              </v-btn>
            </v-col>

            <!-- 驗証碼 -->
            <v-col
              cols="12"
              class="py-0"
            >
              <span class="title--text caption font-weight-medium">
                {{ $t("form.missCaptcha") }}
              </span>
            </v-col>

            <!-- 验证码 輸入 -->
            <v-col
              cols="12"
              class="py-0"
            >
              <v-text-field
                v-model.trim="emailDialog.form.validateCode"
                dense
                :placeholder="$t('form.missVerifyCode')"
                :rules="[rules.required.smsCode, rules.format.verifyMobile]"
                single-line
                outlined
              />
            </v-col>
          </v-row>
        </v-form>
      </div>

      <!-- 取消 -->
      <v-btn
        slot="btn"
        text
        class="subtitle--text"
        @click="emailDialogClose"
      >
        {{ $t("global.cancel") }}
      </v-btn>

      <v-btn
        slot="btn"
        text
        color="primary"
        @click="emailDialogConfirm(emailDialog.form)"
      >
        {{ $t("global.confirmation") }}
      </v-btn>
    </DialogTemplate3>

    <!-- 修改各項目 dialog -->
    <DialogTemplate3 :dialog="edit.dialogStatus">
      <div
        slot="text"
        class="subtitle-1 font-weight-bold"
      >
        <v-form
          ref="editForm"
          v-model="edit.validate"
        >
          <!-- TITLE -->
          <p class="primary py-2 text-center white--text">
            {{ $t('global.edit') + edit.dialogTitle }}
          </p>

          <div class="px-6">
            <!-- 驗証 信箱 === 'email' -->
            <v-row v-if="(validationType === 'email' && edit.type !== 'email') || (edit.type === 'mobile' && validationType !== 'none')">
              <v-col
                cols="12"
                class="py-0 mt-6"
              >
                <v-text-field
                  v-model.trim="emailDialog.form.validateCode"
                  :label="$t('myCenter.emailCaptcha')"
                  outlined
                  :color="theme === 'dark' ? 'secondary' : 'primary'"
                  :rules="[rules.required.smsCode, rules.format.verifyMobile]"
                >
                  <div
                    slot="append"
                  >
                    <span
                      :disabled="emailDialog.validate.sendBtnSec !== 60"
                      block
                      outlined
                      color="primary"
                      class="px-0 caption cursor-pointer"
                      :class="theme === 'dark' ? 'secondary--text' : 'primary--text'"
                      @click="sendEmailValidateCode(emailDialog.form, 'edit')"
                    >
                      <!-- 發送驗證碼 -->
                      <template v-if="emailDialog.validate.sendBtnSec === 60">
                        {{ $t("myCenter.sendVerificationCode") }}
                      </template>

                      <!-- 59后重試 -->
                      <template v-else>
                        {{
                          emailDialog.validate.sendBtnSec + "s" + $t("global.retry")
                        }}
                      </template>
                    </span>
                  </div>
                </v-text-field>
              </v-col>
            </v-row>

            <!-- 驗証 手機 === 'sms' -->
            <v-row v-if="(validationType === 'sms' && edit.type !== 'mobile' )|| (edit.type === 'email' && validationType !== 'none')">
              <v-col
                cols="12"
                class="py-0 mt-4"
              >
                <!-- 手機驗証碼 -->
                <v-text-field
                  v-model.trim="mobileVerifyCodeSMS"
                  :label="$t('myCenter.mobileCaptcha')"
                  :placeholder="$t('form.missVerifyCode')"
                  :rules="[rules.required.smsCode, rules.format.verifyMobile]"
                  outlined
                >
                  <div
                    slot="append"
                  >
                    <!-- 手機發送驗証碼 -->
                    <span
                      :disabled="verifiMobileCodeBtnSec !== 60"
                      block
                      outlined
                      class="px-0 caption"
                      :class="theme === 'dark' ? 'secondary--text' : 'primary--text'"
                      @click="verifiMobileCode"
                    >
                      <!-- 發送驗證碼 -->
                      <template v-if="verifiMobileCodeBtnSec === 60">
                        {{ $t("myCenter.sendVerificationCode") }}
                      </template>

                      <!-- 59后重試 -->
                      <template v-else>
                        {{ verifiMobileCodeBtnSec + "s" + $t("global.retry") }}
                      </template>
                    </span>
                  </div>
                </v-text-field>
              </v-col>
            </v-row>

            <!-- 修改手機 -->
            <template v-if="edit.type === 'mobile'">
              <div class="mt-4">
                <v-text-field
                  v-model.trim="edit.form.contact_no"
                  :label="$t('myCenter.newMobile')"
                  :placeholder="$t('form.missMobile')"
                  class="mt-2"
                  outlined
                  :rules="[
                    rules.required.mobile,
                  ]"
                />
              </div>
            </template>

            <!-- 修改信箱 -->
            <template v-if="edit.type === 'email'">
              <div class="mt-5">
                <v-text-field
                  v-model.trim="edit.form.email"
                  :label="$t('global.editNew') + $t('myCenter.email')"
                  :placeholder="$t('form.missEmail')"
                  class="mt-2"
                  outlined
                  :rules="[rules.required.mail, rules.format.mail]"
                />
              </div>
            </template>

            <!-- 修改姓名 -->
            <template v-if="edit.type === 'username'">
              <div class="mt-4">
                <v-text-field
                  v-model.trim="edit.form.username"
                  :label="$t('global.editNew') + $t('myCenter.bankAccountName')"
                  class="mt-2"
                  outlined
                  :color="theme === 'dark' ? 'secondary' : 'primary'"
                  :rules="[rules.required.userName, rules.length.userName]"
                />
              </div>
            </template>

            <!-- 修改性別 -->
            <template v-if="edit.type === 'gender'">
              <div class="mt-4">
                <v-select
                  v-model="edit.form.gender"
                  :label="$t('myCenter.gender')"
                  :items="genderItems"
                  item-text="gender"
                  item-value="value"
                  outlined
                />
              </div>
            </template>

            <!-- 修改出生日期 -->
            <template v-if="edit.type === 'birthday'">
              <div class="mt-4">
                <!-- 出生日期 -->
                <v-col
                  cols="12"
                  class="py-0 px-0"
                >
                  <!-- 日期選擇器彈窗 -->
                  <v-dialog
                    ref="dialogFrom"
                    v-model="datePicker"
                    persistent
                    width="290px"
                  >
                    <!-- input -->
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="edit.form.birthdate"
                        :label="$t('myCenter.birthday')"
                        :placeholder="$t('form.missBirthday')"
                        :rules="[rules.required.birthdate]"
                        class="mt-2"
                        outlined
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>

                    <!-- 日期選擇器 -->
                    <v-date-picker
                      v-model="edit.form.birthdate"
                      class="bg"
                      :max="legaAge"
                      header-color="bg"
                      color="primary"
                      scrollable
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="subtitle"
                        class="subtitle-1 font-weight-medium"
                        @click="chancelEditPickerDate"
                      >
                        {{ $t("global.cancel") }}
                      </v-btn>

                      <v-btn
                        class="subtitle-1 font-weight-medium"
                        text
                        color="primary"
                        @click="datePicker = false"
                      >
                        {{ $t("global.confirmation") }}
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
              </div>
            </template>

            <!-- 修改取款密碼 -->
            <div
              v-if="edit.type ==='withdrawPassword'"
              class="input"
            >
              <!-- 新取款密碼 -->
              <v-text-field
                v-model.trim="edit.form.withdrawal_key"
                type="password"
                :label="$t('myCenter.newWithdrawPassword')"
                :placeholder="$t('myCenter.newWithdrawPassword')"
                :rules="[rules.required.enterNewPassword, rules.length.pwd]"
                class="mt-4"
                outlined
              />

              <!-- 再次輸入新取款密碼 -->
              <v-text-field
                v-model.trim="edit.check_withdrawal_key"
                type="password"
                :label="$t('myCenter.newWithdrawPasswordConfirm')"
                :placeholder="$t('myCenter.newWithdrawPasswordConfirm')"
                :rules="[
                  rules.required.enterNewPasswordAgain,
                  rules.format.enterNewWithdrawKeyAgain,
                ]"
                class="mt-2"
                outlined
                @keyup.enter="userLoginPasswordChange"
              />
            </div>
          </div>
        </v-form>
      </div>

      <!-- 取消 -->
      <v-btn
        slot="btn"
        text
        class="subtitle--text"
        @click="chancelEditDialog"
      >
        {{ $t("global.cancel") }}
      </v-btn>

      <v-btn
        slot="btn"
        text
        color="primary"
        @click="editUserProfileItem(edit.form)"
      >
        {{ $t("global.confirmation") }}
      </v-btn>
    </DialogTemplate3>
  </div>
</template>

<script>
import profileMixins from '../mixins/profileMixins'

export default {
  mixins: [profileMixins],
}
</script>

<style lang="scss" scoped>
.gender-input {
  height: 56px;
}

</style>
