<template>
  <div>
    <v-container class="pa-0 px-4 ma-0 pb-5 header">
      <!-- 使用者資訊 -->
      <v-row class="userInfo">
        <v-col
          cols="12"
          class="text-center"
        >
          <v-row>
            <v-col
              cols="12"
              class="userInfo__avatar pb-2 mt-2"
            >
              <!-- Liff 模式 (頭像)-->
              <template v-if="isLiffMode">
                <v-avatar
                  size="83"
                  class="mt-2"
                  :style="avatarBorderClr"
                >
                  <img :src="lineAvatar">
                </v-avatar>
              </template>

              <!-- 一般模式 (頭像)-->
              <template v-else>
                <v-avatar
                  v-if="userBaseInfo.avatar"
                  size="83"
                  class="mt-2"
                  @click="preChangeUserAvatar"
                >
                  <img
                    v-if="userBaseInfo.avatar"
                    :src="baseImgUrl + userBaseInfo.avatar"
                    alt="userBaseInfo.username"
                  >
                </v-avatar>
              </template>
            </v-col>

            <!-- 如果未登入 -->
            <template v-if="!isLogin">
              <v-col class="pt-2">
                <!-- 請先登入 btn -->
                <v-btn
                  to="/login"
                  class="mb-4"
                  outlined
                  color="primary"
                >
                  <span class="subtitle-2 primary--text">
                    {{ $t('flashMessage.loginFirst') }}
                  </span>
                </v-btn>
              </v-col>
            </template>

            <!-- 如果已經登入 -->
            <template v-else>
              <!-- 使用者名稱 -->
              <v-col
                cols="12"
                class="userInfo__name py-0 subtitle-2"
              >
                <!-- Liff 模式 -->
                <template v-if="isLiffMode">
                  <span class="mr-1">{{ lineDisplayName }}</span>
                  <span class="mr-1">({{ userData.member.account }})</span>
                </template>

                <!-- 一般模式 -->
                <template v-else>
                  <span class="mr-1 secondary--text">{{ userData.member.account }}</span>
                </template>
              </v-col>
            </template>
          </v-row>
        </v-col>
      </v-row>

      <!-- 錢包相關操作 -->
      <v-row class="walletArea">
        <v-col cols="12">
          <v-row class="px-4 pb-3">
            <!-- 中心錢包 -->
            <v-col
              v-if="isLogin"
              cols="12"
              class="d-flex justify-center align-center"
            >
              <div class="currencySymbol white rounded-circle d-flex justify-center align-center">
                <span class="black--text">
                  {{ currencySymbol_ }}
                </span>
              </div>
              <span class="ml-1 mr-3 caption white--text">{{ player.name }}</span>
              <span class="ml-1 mr-3 white--text">{{ player.balance }}</span>
            </v-col>

            <!-- FREE 錢包 isLiffMode -->
            <v-col
              v-if="isLiffMode"
              cols="12"
              class="d-flex justify-center align-center pt-0"
            >
              <div class="currencySymbol white rounded-circle d-flex justify-center align-center">
                <span class="black--text">
                  {{ currencySymbol_ }}
                </span>
              </div>
              <span class="ml-1 mr-3 caption white--text">{{ free.name }}</span>
              <span class="ml-1 mr-3 white--text">{{ free.balance }}</span>
            </v-col>

            <!-- 快捷功能 -->
            <!-- 取款 -->
            <v-col
              v-if="!isLiffMode"
              class="text-center d-flex justify-center align-center pa-0"
            >
              <v-btn
                block
                to="user/withdrawal"
                text
                height="32"
                class="px-0 secondary"
              >
                <div class="d-flex align-center justify-center primary--text caption font-weight-bold">
                  <Icon
                    data="@icon/user/walletItem/withdrawal.svg"
                    width="23px"
                    height="20px"
                    class="mr-1"
                    :class="[style === 'darkGD' ? 'primary--text' : '']"
                  />
                  {{ $t('global.withdraw') }}
                </div>
              </v-btn>
            </v-col>

            <!-- 轉換 -->
            <v-col class="text-center d-flex justify-center align-center pa-0 mx-1">
              <v-btn
                block
                to="user/transfer"
                text
                height="32"
                class="secondary"
              >
                <div class="d-flex align-center justify-center primary--text caption font-weight-bold">
                  <Icon
                    data="@icon/user/walletItem/transfer.svg"
                    width="23px"
                    height="20px"
                    class="mr-1"
                    :class="[style === 'darkGD' ? 'primary--text' : '']"
                  />
                  {{ $t('global.transferBtn') }}
                </div>
              </v-btn>
            </v-col>

            <!-- 存款 -->
            <v-col
              v-if="!isLiffMode"
              class="text-center d-flex justify-center align-center pa-0"
              :class="[{ 'mt-2': activeLang === 'vi-VN' }]"
            >
              <v-btn
                block
                text
                height="32"
                class="px-0 primary"
                @click="goPageFunc('user/deposit')"
              >
                <div class="d-flex align-center justify-center secondary--text caption font-weight-bold">
                  <Icon
                    data="@icon/user/walletItem/deposit.svg"
                    width="23px"
                    height="20px"
                    class="mr-1"
                    :class="[style === 'darkGD' ? 'primary--text' : '']"
                  />
                  {{ $t('global.deposit') }}
                </div>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-container :class="currentDevice === 'ios' ? 'pb-72' : 'pb-56'">
      <!-- 替換頭像 DIALOG -->
      <Dialog
        :dialog="dialog.status"
        :width="320"
        :height="302"
      >
        <!-- 我的頭像 -->
        <span
          slot="title"
          class="text-h6"
        >
          {{ $t('myCenter.myAvatar') }}
        </span>

        <div
          slot="text"
          class="d-flex flex-column align-center"
        >
          <!-- 預覽頭像 -->
          <v-avatar
            size="83"
            color="primary"
            class="mt-2"
            :style="avatarBorderClr"
          >
            <img
              v-if="userBaseInfo.avatar || dialog.form.avatar"
              :src="baseImgUrl + dialog.form.avatar || baseImgUrl + userBaseInfo.avatar"
              alt="userBaseInfo.username"
            >
          </v-avatar>

          <!-- 頭像預覽 -->
          <span class="caption title--text mt-1">
            {{ $t('myCenter.avatarPreview') }}
          </span>

          <div class="d-flex justify-space-between w-100 mt-1 px-6">
            <!-- 頭像列表 -->
            <v-avatar
              v-for="item in dialog.avatarList"
              :key="item"
              size="48"
              color="primary"
              class="mt-2"
              :style="dialog.form.avatar === item ? avatarBorderClr : ''"
              @click="dialog.form.avatar = item"
            >
              <img :src="baseImgUrl + item">
            </v-avatar>
          </div>
        </div>
        <v-btn
          slot="btn"
          text
          class="subtitle"
          @click="closeDialog"
        >
          {{ $t('global.cancel') }}
        </v-btn>
        <v-btn
          slot="btn"
          text
          class="primary--text"
          @click="setUserAvatar"
        >
          {{ $t('global.save') }}
        </v-btn>
      </Dialog>
      <!-- 其它頁面導引 -->
      <v-row>
        <!-- 交易紀錄 -->
        <v-col
          cols="12"
        >
          <v-row class="px-4 py-3 navBar">
            <v-col
              :cols="isLiffMode ? 6: itemsWidth"
              class="text-center px-1 py-1"
            >
              <v-btn
                block
                depressed
                height="80"
                color="card1"
                @click="goPageFunc('user/trade')"
              >
                <div class=" d-flex flex-column align-center justify-center icon--text">
                  <Icon
                    data="@icon/user/mainControl/transfer.svg"
                    width="48px"
                    height="48px"
                    :class="[ theme === 'dark' ? 'secondary--text' : 'primary--text' ]"
                  />
                  <span class="caption font-weight-bold">
                    {{ $t('global.transactionRecord') }}
                  </span>
                </div>
              </v-btn>
            </v-col>

            <!-- 投注紀錄 -->
            <v-col
              :cols="isLiffMode ? 6: itemsWidth"
              class="text-center px-1 py-1 center-btn"
            >
              <v-divider
                vertical
                class="pb-3 icon--text p-absolute z-index-1 left-0 top-0"
              />
              <v-divider
                v-if="!isLiffMode"
                vertical
                class="pb-3 icon--text p-absolute z-index-1 right-0 top-0"
              />
              <v-btn
                to="user/wager"
                block
                depressed
                height="80"
                color="card1"
              >
                <div class="h-100 d-flex flex-column align-center justify-center icon--text">
                  <Icon
                    data="@icon/user/mainControl/wager.svg"
                    width="48px"
                    height="48px"
                    class="pa-2"
                    :class="[ theme === 'dark' ? 'secondary--text' : 'primary--text' ]"
                  />
                  <span class="caption font-weight-bold">
                    {{ $t('global.wagerRecord') }}
                  </span>
                </div>
              </v-btn>
            </v-col>

            <!-- 兌獎紀錄 -->
            <v-col
              v-if="!isLiffMode"
              :cols="itemsWidth"
              class="text-center px-1 py-1"
            >
              <v-btn
                to="user/prize"
                block
                depressed
                height="80"
                color="card1"
              >
                <div class="h-100 d-flex flex-column align-center justify-center icon--text">
                  <Icon
                    data="@icon/user/mainControl/gift.svg"
                    width="48"
                    height="48"
                    :class="[ theme === 'dark' ? 'secondary--text' : 'primary--text' ]"
                  />
                  <span class="caption font-weight-bold">
                    {{ $t('global.prizeRecord') }}
                  </span>
                </div>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>

        <!-- 頁面連結 -->
        <v-col
          v-if="!isLiffMode"
          cols="12"
          class="mt-4 pt-1 px-4"
        >
          <div
            class="rounded-lg px-4"
            :class="theme === 'dark' ? 'black' : 'white' "
          >
            <router-link
              v-for="(item, index) in pageItem"
              v-show="!noShowRouterLink.includes(Object.keys(item)[0])"
              :key="index"
              class="text-decoration-none title--text pageLink"
              :to="`/${ Object.keys(item)[0] }`"
            >
              <v-divider
                v-show="index !== 0"
                class="pb-3 icon--text"
              />
              <div
                class="d-flex mb-2 px-2"
                :class="[{'pt-3': index === 0}]"
              >
                <span class="caption font-weight-medium">
                  {{ Object.values(item)[0] }}
                </span>
                <v-spacer />
                <v-icon right>
                  mdi-chevron-right
                </v-icon>
              </div>
            </router-link>

            <!-- 切換語系 -->
            <div class="text-decoration-none title--text pageLink">
              <v-divider class="pb-3 icon--text" />
              <div
                class="d-flex pb-3 px-2"
                @click="language.status = !language.status"
              >
                <span class="caption font-weight-medium">
                  {{ $t('myCenter.language') }}
                </span>
                <v-spacer />
                <v-icon right>
                  mdi-chevron-right
                </v-icon>
              </div>
            </div>
          </div>
        </v-col>

        <v-col
          v-if="isLogin"
          cols="12"
          class="px-4 mb-4 pt-0 mt-4"
        >
          <v-btn
            block
            height="40"
            color="navBar"
            @click="userLogout"
          >
            <span
              class="subtitle-2 font-weight-medium"
              :class="theme === 'dark' ? 'secondary--text' : 'primary--text'"
            >
              {{ $t('subHeader.logout') }}
            </span>
          </v-btn>
        </v-col>
      </v-row>

      <!-- 語系 popup -->
      <v-bottom-sheet
        v-model="language.status"
        :fullscreen="true"
      >
        <v-sheet
          class="text-center"
          height="200px"
        >
          <v-card
            class="mx-auto bg"
            height="100vh"
          >
            <v-card-text class="pa-0">
              <!-- 關閉切換語系 -->
              <v-toolbar class="primary">
                <v-btn
                  icon
                  @click="language.status = !language.status"
                >
                  <Icon
                    data="@icon/toolbar/toolbar_goback_arrow.svg"
                    width="16px"
                    height="16px"
                    class="white--text"
                  />
                </v-btn>
                <v-toolbar-title class="white--text">
                  {{ $t('myCenter.language') }}
                </v-toolbar-title>
                <v-spacer />
              </v-toolbar>

              <!-- 搜尋 -->
              <v-list
                three-line
                subheader
                class="bg"
              >
                <div class="search-area">
                  <v-text-field
                    id="searchInput"
                    v-model.trim="language.searchKeyword"
                    autofocus
                    class="mx-4 my-3 card1 pt-0"
                    hide-details
                    :placeholder="$t('game.searchGame')"
                    rounded
                    height="48"
                    autocomplete="off"
                  >
                    <!-- 放大鏡 -->
                    <div
                      slot="prepend-inner"
                      class="mt-1"
                    >
                      <v-btn
                        slot="btn"
                        icon
                      >
                        <Icon
                          data="@icon/search.svg"
                          width="24"
                          height="24"
                          class="title--text"
                        />
                      </v-btn>
                    </div>
                  </v-text-field>
                </div>
              </v-list>

              <v-list
                v-for="(lang, index) in language.resultLangs"
                :key="lang._id"
                class="bg text-left px-4 py-0"
              >
                <!-- 切換本地語系 -->
                <v-list-item
                  class="px-0"
                  :class="[{'primary rounded' : activeLang === lang.code }]"
                  @click="settingClientLang(lang.code)"
                >
                  <!-- ICON -->
                  <div class="local-name mr-3">
                    <v-img
                      v-if="false"
                      :src="baseImgUrl + lang.icon"
                      max-width="20"
                    />
                  </div>
                  <!-- 默認名稱 -->
                  <div
                    class="default-name"
                    :class="[ activeLang === lang.code ? 'white--text' : 'title--text']"
                  >
                    {{ lang.default_name }}
                    <span class="ml-2">
                      {{ lang.remark }}
                    </span>
                  </div>
                </v-list-item>
                <v-divider v-if="index !== language.resultLangs.length - 1" />
              </v-list>
            </v-card-text>
          </v-card>
        </v-sheet>
      </v-bottom-sheet>
    </v-container>
  </div>
</template>

<script>
import indexMixins from '../mixins/index'

export default {
  mixins: [indexMixins],
}
</script>

<style lang="scss" scoped>
.currencySymbol {
  width: 20px;
  height: 20px;
}
.header {
  background: center / cover no-repeat url('~@/assets/icons/user/mainControl/user-template3.png')
}
.center-btn {
  position: relative;
}
.left-0 {
  left: 0;
}
.right-0 {
  right: 0;
}
</style>
