<template>
  <div id="record">
    <v-container>
      <!-- 快選日期 -->
      <v-row class="pt-3 px-3 d-none quick-time-picker">
        <v-col
          v-for="(item, index) in dataBtnText"
          :key="index"
          class="rounded-lg mx-1 subtitle-2 px-0 py-2 text-center d-flex align-center justify-center"
          :class="[
            dateBtn === index
              ? 'secondary white--text'
              : 'inputBg subtitle--text',
          ]"
          @click="changeData(...Object.keys(item), index)"
        >
          {{ Object.values(item)[0] }}
        </v-col>
        <!-- 自定義 data -->
        <v-col
          class="rounded-lg mx-1 subtitle-2 px-0 py-2 text-center d-flex align-center justify-center"
          :class="[
            dateBtn === 4 ? 'secondary white--text' : 'inputBg subtitle--text',
          ]"
          @click="customDate"
        >
          {{ $t('myCenter.customize') }}
        </v-col>
      </v-row>

      <v-row v-show="showPickerDate">
        <v-col cols="12">
          <CustomizeDate @get-data="pickerDateEv" />
        </v-col>
      </v-row>

      <v-row
        class="px-2 mt-0"
        :class="{'pt-3': !showPickerDate}"
      >
        <v-col
          v-for="(item, index) in recordItem"
          :key="index"
          cols="6"
          class="pa-1"
        >
          <div
            class="record card1 rounded d-flex flex-column align-center justify-center h-79"
          >
            <div
              class="record__title subtitle-2"
              :class="winlostDynamicColor(Object.keys(item)[0], theme)"
            >
              {{ record[Object.keys(item)[0]] }}
            </div>
            <div class="record__num caption subtitle--text mt-1 px-1 text-center">
              {{ Object.values(item)[0] }}
            </div>
          </div>
        </v-col>
      </v-row>

      <!-- wallet -->
      <v-row class="px-3 pb-2">
        <template
          v-if="
            (record.wallet && record.wallet.length < 1) || record.length === 0
          "
        >
          <v-col
            cols="12"
            class="mt-6"
          >
            <NodataTemplate3 :text="$t('global.noRecords')" />
          </v-col>
        </template>

        <template v-else>
          <v-col
            v-for="item in record.wallet"
            :key="item.code"
            cols="12"
            class="card1 rounded-lg my-1 pa-0 caption round-t-lg"
          >
            <!-- 遊戲名稱 -->
            <div
              class="px-4 py-1 rounded-t-lg wallet-title-color white--text"
            >
              {{ item.title }}
            </div>

            <!-- 總投注 -->
            <div class="px-4 pt-4 title--text">
              <span class="subtitle--text mr-3">
                {{ $t('myCenter.totalWager') }}
              </span>
              {{ item.bet }}
            </div>

            <!-- 總輸贏 -->
            <div class="px-4 pb-4 pt-1 subtile--text">
              <span class="subtitle--text mr-3">
                {{ $t('myCenter.totalWinloss') }}
              </span>
              <span :class="[theme === 'dark' ? 'secondary--text' : 'primary--text']">
                {{ item.winlost }}
              </span>
            </div>
          </v-col>
        </template>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import recordMixins from '../mixins/recordMixins'

export default {
  mixins: [recordMixins],
}
</script>

<style lang="scss" scoped>
.h-79 {
	height: 79px;
}
.wallet-title-color {
  background: linear-gradient(270deg, #BE5A4F -6.94%, #C03111 100%);
}
</style>
