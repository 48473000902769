var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "my-center" },
    [
      _c(
        "v-container",
        { class: _vm.currentDevice === "ios" ? "pb-72" : "pb-56" },
        [
          _c(
            "Dialog",
            { attrs: { dialog: _vm.dialog.status, width: 320, height: 302 } },
            [
              _c(
                "span",
                {
                  staticClass: "text-h6",
                  attrs: { slot: "title" },
                  slot: "title",
                },
                [_vm._v(" " + _vm._s(_vm.$t("myCenter.myAvatar")) + " ")]
              ),
              _c(
                "div",
                {
                  staticClass: "d-flex flex-column align-center",
                  attrs: { slot: "text" },
                  slot: "text",
                },
                [
                  _c(
                    "v-avatar",
                    {
                      staticClass: "mt-2",
                      style: _vm.avatarBorderClr,
                      attrs: { size: "83", color: "primary" },
                    },
                    [
                      _vm.userBaseInfo.avatar || _vm.dialog.form.avatar
                        ? _c("img", {
                            attrs: {
                              src:
                                _vm.baseImgUrl + _vm.dialog.form.avatar ||
                                _vm.baseImgUrl + _vm.userBaseInfo.avatar,
                              alt: "userBaseInfo.username",
                            },
                          })
                        : _vm._e(),
                    ]
                  ),
                  _c("span", { staticClass: "caption title--text mt-1" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("myCenter.avatarPreview")) + " "
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-space-between w-100 mt-1 px-6",
                    },
                    _vm._l(_vm.dialog.avatarList, function (item) {
                      return _c(
                        "v-avatar",
                        {
                          key: item,
                          staticClass: "mt-2",
                          style:
                            _vm.dialog.form.avatar === item
                              ? _vm.avatarBorderClr
                              : "",
                          attrs: { size: "48", color: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.dialog.form.avatar = item
                            },
                          },
                        },
                        [_c("img", { attrs: { src: _vm.baseImgUrl + item } })]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "subtitle",
                  attrs: { slot: "btn", text: "" },
                  on: { click: _vm.closeDialog },
                  slot: "btn",
                },
                [_vm._v(" " + _vm._s(_vm.$t("global.cancel")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "primary--text",
                  attrs: { slot: "btn", text: "" },
                  on: { click: _vm.setUserAvatar },
                  slot: "btn",
                },
                [_vm._v(" " + _vm._s(_vm.$t("global.save")) + " ")]
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "userInfo avatar-type3" },
            [
              _c(
                "v-col",
                { staticClass: "text-center", attrs: { cols: "12" } },
                [
                  _c("div", { staticClass: "avatar-row" }, [
                    _c(
                      "div",
                      { staticClass: "avatar-left" },
                      [
                        _c(
                          "v-col",
                          { staticClass: "userInfo__avatar pb-2" },
                          [
                            _vm.isLiffMode
                              ? [
                                  _c(
                                    "v-avatar",
                                    {
                                      staticClass: "mt-2",
                                      style: _vm.avatarBorderClr,
                                      attrs: { size: "83" },
                                    },
                                    [
                                      _c("img", {
                                        attrs: { src: _vm.lineAvatar },
                                      }),
                                    ]
                                  ),
                                ]
                              : [
                                  _c(
                                    "v-avatar",
                                    {
                                      style: _vm.avatarBorderClr,
                                      attrs: { size: "55" },
                                      on: { click: _vm.preChangeUserAvatar },
                                    },
                                    [
                                      _vm.userBaseInfo.avatar
                                        ? _c("img", {
                                            attrs: {
                                              src:
                                                _vm.baseImgUrl +
                                                _vm.userBaseInfo.avatar,
                                              alt: "userBaseInfo.username",
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                          ],
                          2
                        ),
                        !_vm.isLogin
                          ? [
                              _c(
                                "v-col",
                                { staticClass: "userInfo__name subtitle-2" },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$t("flashMessage.loginFirst"))
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          : [
                              _c(
                                "v-col",
                                { staticClass: "userInfo__name subtitle-2" },
                                [
                                  _vm.isLiffMode
                                    ? [
                                        _c("span", [
                                          _vm._v(_vm._s(_vm.lineDisplayName)),
                                        ]),
                                        _c("span", [
                                          _vm._v(
                                            "(" +
                                              _vm._s(
                                                _vm.userData.member.account
                                              ) +
                                              ")"
                                          ),
                                        ]),
                                      ]
                                    : [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.userData.member.account)
                                          ),
                                        ]),
                                      ],
                                  _vm.showVip
                                    ? _c("Icon", {
                                        staticClass: "user-vip-diamond",
                                        attrs: {
                                          data: require("@icon/vip/diamond.svg"),
                                          width: "16px",
                                          height: "13px",
                                          color:
                                            "#01D0FB #DFF6FD #99E6FC #F2FBFF #DFF6FD #C8EFFE #99E6FC #62DBFB",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ],
                      ],
                      2
                    ),
                    _c(
                      "div",
                      { staticClass: "avatar-right" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "text-decoration-none",
                            attrs: { to: _vm.isLogin ? "/service" : "/login" },
                          },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "round-btn",
                                style: _vm.gradientBg,
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "font-weight-bold subtitle-2",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.isLogin
                                            ? _vm.$t("service.customerService")
                                            : _vm.$t("subHeader.login")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "wallet-vip-block" },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "wallet-block",
                      style: {
                        "padding-bottom":
                          _vm.isLogin && _vm.showVip ? "0" : "12px",
                      },
                      attrs: { cols: "12" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "wallet-amount" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "text-left font-weight-bold caption grey-text",
                              attrs: { cols: "12" },
                            },
                            [
                              _c("Icon", {
                                staticClass: "secondary--text",
                                attrs: {
                                  data: require("@icon/user/walletItem/wallet.svg"),
                                  width: "18px",
                                  height: "18px",
                                },
                              }),
                              _c("span", { staticClass: "grey-text" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("myCenter.mainWallet")) +
                                    " "
                                ),
                              ]),
                              _vm.isLogin
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "refresh-btn",
                                      attrs: { slot: "btn", icon: "" },
                                      on: { click: _vm.getUserBalance },
                                      slot: "btn",
                                    },
                                    [
                                      _c("Icon", {
                                        staticClass: "btnText--text",
                                        attrs: {
                                          data: require("@icon/user/walletItem/refresh.svg"),
                                          width: "16px",
                                          height: "16px",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "btnText--text p-l-20",
                              attrs: { cols: "12" },
                            },
                            [
                              _c("span", [
                                _vm._v(" " + _vm._s(_vm.currencySymbol_) + " "),
                              ]),
                              _c("span", { staticClass: "user-wallet" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.isLogin ? _vm.player.balance : "--"
                                  )
                                ),
                              ]),
                            ]
                          ),
                          _vm.isLiffMode && _vm.isLogin
                            ? [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-left font-weight-bold caption grey-text",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c("Icon", {
                                      staticClass: "secondary--text",
                                      attrs: {
                                        data: require("@icon/user/walletItem/wallet.svg"),
                                        width: "18px",
                                        height: "18px",
                                      },
                                    }),
                                    _vm._v(" " + _vm._s(_vm.free.name) + " "),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "btnText--text p-l-20",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        " " + _vm._s(_vm.currencySymbol_) + " "
                                      ),
                                    ]),
                                    _c("span", { staticClass: "user-wallet" }, [
                                      _vm._v(_vm._s(_vm.free.balance)),
                                    ]),
                                  ]
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _c(
                        "div",
                        { staticClass: "wallet-buttons" },
                        [
                          !_vm.isLiffMode
                            ? _c(
                                "v-col",
                                {
                                  staticClass:
                                    "text-center d-flex justify-center align-center pa-0",
                                  attrs: { cols: "3" },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "rectangle-btn caption font-weight-bold",
                                      style: _vm.gradientBg,
                                      attrs: {
                                        to: "user/withdrawal",
                                        block: "",
                                        text: "",
                                        height: "56",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("global.withdraw")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "text-center d-flex justify-center align-center pa-0",
                              attrs: { cols: "3" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "rectangle-btn caption font-weight-bold",
                                  style: _vm.gradientBg,
                                  attrs: {
                                    to: "user/transfer",
                                    block: "",
                                    text: "",
                                    height: "56",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("global.transferBtn")) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          !_vm.isLiffMode
                            ? _c(
                                "v-col",
                                {
                                  staticClass:
                                    "text-center d-flex justify-center align-center pa-0",
                                  attrs: { cols: "3" },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "rectangle-btn caption font-weight-bold",
                                      style: _vm.gradientBg,
                                      attrs: {
                                        block: "",
                                        text: "",
                                        height: "56",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.goPageFunc("user/deposit")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("global.deposit")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm.isLogin && _vm.showVip
                    ? _c(
                        "v-col",
                        {
                          staticClass:
                            "userInfo__progress vip d-flex align-center",
                          attrs: { cols: "12" },
                        },
                        [
                          _vm.nowVipData.logo
                            ? _c("v-img", {
                                staticClass: "vip__now",
                                attrs: {
                                  src: _vm.baseImgUrl + _vm.nowVipData.logo,
                                  "max-height": "28",
                                  "max-width": "52",
                                },
                              })
                            : _vm._e(),
                          _c("v-progress-linear", {
                            staticClass: "mx-1 vip__progress",
                            attrs: {
                              rounded: "",
                              value: _vm.vipProgress.progress,
                              color: "primary",
                              height: "9",
                            },
                          }),
                          _vm.nextVipData
                            ? _c("v-img", {
                                staticClass: "vip__next",
                                attrs: {
                                  src: _vm.baseImgUrl + _vm.nextVipData.logo,
                                  "max-height": "28",
                                  "max-width": "52",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isLogin && _vm.showVip
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12 text-left subtitle-2 vip-text" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "vip__description caption grey-text",
                            },
                            [
                              _vm.nextVipData
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          `${_vm.$t("myCenter.nextAmt")} : ${
                                            _vm.upgradeNeedTurnover
                                          }`
                                        )
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          `${_vm.$t(
                                            "myCenter.nextDeposit"
                                          )} : ${_vm.upgradeNeedDeposit}`
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "vip-more caption grey-text" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "grey-text",
                                  attrs: { to: "/user/vip" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("myCenter.vipMore") + " >"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "links-block shadow-round-container" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "links-block-title",
                          attrs: { cols: "12" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "links-block-title-text font-weight-bold",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    `${_vm.$t(
                                      "myCenter.benefitCenter"
                                    )} ${_vm.$t("myCenter.benefitJoy")}`
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "links-block-title-btn" },
                            [
                              _c(
                                "v-btn",
                                {
                                  style: _vm.gradientBg,
                                  attrs: {
                                    to: "/bonus",
                                    block: "",
                                    text: "",
                                    height: "34",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        `${_vm.$t("myCenter.getProfit")} >`
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center px-1 py-1",
                          attrs: { cols: _vm.itemsWidth },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                to: "user/personal",
                                block: "",
                                height: "80",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-column align-center justify-center link-text",
                                },
                                [
                                  _c("Icon", {
                                    staticClass: "secondary--text",
                                    attrs: {
                                      data: require("@icon/user/mainControl/personal2.svg"),
                                      width: "34px",
                                      height: "34px",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "caption font-weight-bold" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "myCenter.personalInformation"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm.showCard
                        ? _c(
                            "v-col",
                            {
                              staticClass: "text-center px-1 py-1",
                              attrs: { cols: _vm.itemsWidth },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { block: "", height: "80" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.goPageFunc("user/card")
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex flex-column align-center justify-center link-text",
                                    },
                                    [
                                      _c("Icon", {
                                        staticClass: "secondary--text",
                                        attrs: {
                                          data: require("@icon/user/mainControl/card.svg"),
                                          width: "34px",
                                          height: "34px",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "caption font-weight-bold",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("global.cards")) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center px-1 py-1",
                          attrs: { cols: _vm.itemsWidth },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { block: "", height: "80" },
                              on: {
                                click: function ($event) {
                                  return _vm.goPageFunc("user/trade")
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-column align-center justify-center link-text",
                                },
                                [
                                  _c("Icon", {
                                    staticClass: "secondary--text",
                                    attrs: {
                                      data: require("@icon/user/mainControl/transfer2.svg"),
                                      width: "34px",
                                      height: "34px",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "caption font-weight-bold" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("global.transactionRecord")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      !_vm.isLiffMode
                        ? _c(
                            "v-col",
                            {
                              staticClass: "text-center px-1 py-1",
                              attrs: { cols: _vm.itemsWidth },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    to: "user/news",
                                    block: "",
                                    height: "80",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "h-100 d-flex flex-column align-center justify-center link-text",
                                    },
                                    [
                                      _c("Icon", {
                                        staticClass: "secondary--text",
                                        attrs: {
                                          data: require("@icon/user/mainControl/msg2.svg"),
                                          width: "34px",
                                          height: "34px",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "caption font-weight-bold",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("myCenter.news")) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center px-1 py-1",
                          attrs: { cols: _vm.itemsWidth },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                to: "recommend",
                                block: "",
                                height: "80",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "h-100 d-flex flex-column align-center justify-center link-text",
                                },
                                [
                                  _c("Icon", {
                                    staticClass: "secondary--text",
                                    attrs: {
                                      data: require("@icon/user/mainControl/vip2.svg"),
                                      width: "34px",
                                      height: "34px",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "caption font-weight-bold" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("myCenter.recommendBonus")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center px-1 py-1",
                          attrs: { cols: _vm.itemsWidth },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                to: "user/wager",
                                block: "",
                                height: "80",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "h-100 d-flex flex-column align-center justify-center link-text",
                                },
                                [
                                  _c("Icon", {
                                    staticClass: "secondary--text",
                                    attrs: {
                                      data: require("@icon/user/mainControl/wager2.svg"),
                                      width: "34px",
                                      height: "34px",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "caption font-weight-bold" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("global.wagerRecord")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      !_vm.isLiffMode
                        ? _c(
                            "v-col",
                            {
                              staticClass: "text-center px-1 py-1",
                              attrs: { cols: _vm.itemsWidth },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    to: "user/prize",
                                    block: "",
                                    height: "80",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "h-100 d-flex flex-column align-center justify-center link-text",
                                    },
                                    [
                                      _c("Icon", {
                                        staticClass: "secondary--text",
                                        attrs: {
                                          data: require("@icon/user/mainControl/gift2.svg"),
                                          width: "34px",
                                          height: "34px",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "caption font-weight-bold",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("global.prizeRecord")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center px-1 py-1",
                          attrs: { cols: _vm.itemsWidth },
                        },
                        [
                          _c(
                            "v-btn",
                            { attrs: { to: "agent", block: "", height: "80" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "h-100 d-flex flex-column align-center justify-center link-text",
                                },
                                [
                                  _c("Icon", {
                                    staticClass: "secondary--text",
                                    attrs: {
                                      data: require("@icon/user/mainControl/help2.svg"),
                                      width: "34px",
                                      height: "34px",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "caption font-weight-bold" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("myCenter.joinUs")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm.isLiffMode || _vm.withdrawalIsFlatten
                        ? _c(
                            "v-col",
                            {
                              staticClass: "text-center px-1 py-1",
                              attrs: { cols: _vm.itemsWidth },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    to: "faq/problem",
                                    block: "",
                                    height: "80",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "h-100 d-flex flex-column align-center justify-center link-text",
                                    },
                                    [
                                      _c("Icon", {
                                        staticClass: "secondary--text",
                                        attrs: {
                                          data: require("@icon/user/mainControl/help.svg"),
                                          width: "34px",
                                          height: "34px",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "caption font-weight-bold",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("myCenter.commonProblem")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-col", { attrs: { cols: "12" } }, [
                _vm.isLogin
                  ? _c(
                      "div",
                      {
                        staticClass: "shadow-round-btn",
                        on: { click: _vm.userLogout },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "caption font-weight-bold" },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("subHeader.logout")) + " "
                            ),
                          ]
                        ),
                        _c("Icon", {
                          staticClass: "secondary--text",
                          attrs: {
                            data: require("@icon/user/mainControl/logout.svg"),
                            width: "20px",
                            height: "20px",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            1
          ),
          _c(
            "v-bottom-sheet",
            {
              attrs: { fullscreen: true },
              model: {
                value: _vm.language.status,
                callback: function ($$v) {
                  _vm.$set(_vm.language, "status", $$v)
                },
                expression: "language.status",
              },
            },
            [
              _c(
                "v-sheet",
                { staticClass: "text-center", attrs: { height: "200px" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "mx-auto bg", attrs: { height: "100vh" } },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "pa-0" },
                        [
                          _c(
                            "v-toolbar",
                            { staticClass: "bg" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "" },
                                  on: {
                                    click: function ($event) {
                                      _vm.language.status = !_vm.language.status
                                    },
                                  },
                                },
                                [
                                  _c("Icon", {
                                    staticClass: "link-text",
                                    attrs: {
                                      data: require("@icon/toolbar/arrow_left.svg"),
                                      width: "16px",
                                      height: "16px",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("v-toolbar-title", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("myCenter.language")) +
                                    " "
                                ),
                              ]),
                              _c("v-spacer"),
                            ],
                            1
                          ),
                          _c(
                            "v-list",
                            {
                              staticClass: "bg",
                              attrs: { "three-line": "", subheader: "" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "search-area" },
                                [
                                  _c(
                                    "v-text-field",
                                    {
                                      staticClass: "mx-4 mt-2 inputBg",
                                      attrs: {
                                        id: "searchInput",
                                        autofocus: "",
                                        "hide-details": "",
                                        placeholder: _vm.$t("game.searchGame"),
                                        rounded: "",
                                        autocomplete: "off",
                                        dense: "",
                                      },
                                      model: {
                                        value: _vm.language.searchKeyword,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.language,
                                            "searchKeyword",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "language.searchKeyword",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "mt-0",
                                          attrs: { slot: "prepend-inner" },
                                          slot: "prepend-inner",
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { slot: "btn", icon: "" },
                                              slot: "btn",
                                            },
                                            [
                                              _c("Icon", {
                                                staticClass: "white--text",
                                                attrs: {
                                                  data: require("@icon/search.svg"),
                                                  width: "24",
                                                  height: "24",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._l(_vm.language.resultLangs, function (lang) {
                            return _c(
                              "v-list",
                              {
                                key: lang._id,
                                staticClass: "bg text-left px-4 py-0",
                              },
                              [
                                _c(
                                  "v-list-item",
                                  {
                                    class: [
                                      {
                                        "primary rounded-xl":
                                          _vm.activeLang === lang.code,
                                      },
                                    ],
                                    on: {
                                      click: function ($event) {
                                        return _vm.settingClientLang(lang.code)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "local-name mr-3 title--text",
                                      },
                                      [
                                        _c("v-img", {
                                          attrs: {
                                            src: _vm.baseImgUrl + lang.icon,
                                            "max-width": "20",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "default-name title--text",
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(lang.default_name) + " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c("v-divider", { staticClass: "divider" }),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }