// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/icons/user/mainControl/user-template3.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n/* Globals vuetify 客製變數 */\n.currencySymbol[data-v-0c543b52] {\n  width: 20px;\n  height: 20px;\n}\n.header[data-v-0c543b52] {\n  background: center/cover no-repeat url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.center-btn[data-v-0c543b52] {\n  position: relative;\n}\n.left-0[data-v-0c543b52] {\n  left: 0;\n}\n.right-0[data-v-0c543b52] {\n  right: 0;\n}", ""]);
// Exports
module.exports = exports;
