var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "record" } },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { staticClass: "pt-3 px-3 d-none quick-time-picker" },
            [
              _vm._l(_vm.dataBtnText, function (item, index) {
                return _c(
                  "v-col",
                  {
                    key: index,
                    staticClass:
                      "rounded-lg mx-1 subtitle-2 px-0 py-2 text-center d-flex align-center justify-center",
                    class: [
                      _vm.dateBtn === index
                        ? "secondary white--text"
                        : "inputBg subtitle--text",
                    ],
                    on: {
                      click: function ($event) {
                        _vm.changeData(...Object.keys(item), index)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(Object.values(item)[0]) + " ")]
                )
              }),
              _c(
                "v-col",
                {
                  staticClass:
                    "rounded-lg mx-1 subtitle-2 px-0 py-2 text-center d-flex align-center justify-center",
                  class: [
                    _vm.dateBtn === 4
                      ? "secondary white--text"
                      : "inputBg subtitle--text",
                  ],
                  on: { click: _vm.customDate },
                },
                [_vm._v(" " + _vm._s(_vm.$t("myCenter.customize")) + " ")]
              ),
            ],
            2
          ),
          _c(
            "v-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showPickerDate,
                  expression: "showPickerDate",
                },
              ],
            },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [_c("CustomizeDate", { on: { "get-data": _vm.pickerDateEv } })],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            {
              staticClass: "px-2 mt-0",
              class: { "pt-3": !_vm.showPickerDate },
            },
            _vm._l(_vm.recordItem, function (item, index) {
              return _c(
                "v-col",
                { key: index, staticClass: "pa-1", attrs: { cols: "6" } },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "record card1 rounded d-flex flex-column align-center justify-center h-79",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "record__title subtitle-2",
                          class: _vm.winlostDynamicColor(
                            Object.keys(item)[0],
                            _vm.theme
                          ),
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.record[Object.keys(item)[0]]) + " "
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "record__num caption subtitle--text mt-1 px-1 text-center",
                        },
                        [_vm._v(" " + _vm._s(Object.values(item)[0]) + " ")]
                      ),
                    ]
                  ),
                ]
              )
            }),
            1
          ),
          _c(
            "v-row",
            { staticClass: "px-3 pb-2" },
            [
              (_vm.record.wallet && _vm.record.wallet.length < 1) ||
              _vm.record.length === 0
                ? [
                    _c(
                      "v-col",
                      { staticClass: "mt-6", attrs: { cols: "12" } },
                      [
                        _c("NodataTemplate3", {
                          attrs: { text: _vm.$t("global.noRecords") },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._l(_vm.record.wallet, function (item) {
                    return _c(
                      "v-col",
                      {
                        key: item.code,
                        staticClass:
                          "card1 rounded-lg my-1 pa-0 caption round-t-lg",
                        attrs: { cols: "12" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "px-4 py-1 rounded-t-lg wallet-title-color white--text",
                          },
                          [_vm._v(" " + _vm._s(item.title) + " ")]
                        ),
                        _c("div", { staticClass: "px-4 pt-4 title--text" }, [
                          _c("span", { staticClass: "subtitle--text mr-3" }, [
                            _vm._v(
                              " " + _vm._s(_vm.$t("myCenter.totalWager")) + " "
                            ),
                          ]),
                          _vm._v(" " + _vm._s(item.bet) + " "),
                        ]),
                        _c(
                          "div",
                          { staticClass: "px-4 pb-4 pt-1 subtile--text" },
                          [
                            _c("span", { staticClass: "subtitle--text mr-3" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("myCenter.totalWinloss")) +
                                  " "
                              ),
                            ]),
                            _c(
                              "span",
                              {
                                class: [
                                  _vm.theme === "dark"
                                    ? "secondary--text"
                                    : "primary--text",
                                ],
                              },
                              [_vm._v(" " + _vm._s(item.winlost) + " ")]
                            ),
                          ]
                        ),
                      ]
                    )
                  }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }