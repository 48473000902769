<template>
  <div class="my-center">
    <v-container :class="currentDevice === 'ios' ? 'pb-72' : 'pb-56'">
      <!-- 替換頭像 DIALOG -->
      <Dialog
        :dialog="dialog.status"
        :width="320"
        :height="302"
      >
        <!-- 我的頭像 -->
        <span
          slot="title"
          class="text-h6"
        >
          {{ $t('myCenter.myAvatar') }}
        </span>

        <div
          slot="text"
          class="d-flex flex-column align-center"
        >
          <!-- 預覽頭像 -->
          <v-avatar
            size="83"
            color="primary"
            class="mt-2"
            :style="avatarBorderClr"
          >
            <img
              v-if="userBaseInfo.avatar || dialog.form.avatar"
              :src="baseImgUrl + dialog.form.avatar || baseImgUrl + userBaseInfo.avatar"
              alt="userBaseInfo.username"
            >
          </v-avatar>

          <!-- 頭像預覽 -->
          <span class="caption title--text mt-1">
            {{ $t('myCenter.avatarPreview') }}
          </span>

          <div class="d-flex justify-space-between w-100 mt-1 px-6">
            <!-- 頭像列表 -->
            <v-avatar
              v-for="item in dialog.avatarList"
              :key="item"
              size="48"
              color="primary"
              class="mt-2"
              :style="dialog.form.avatar === item ? avatarBorderClr : ''"
              @click="dialog.form.avatar = item"
            >
              <img :src="baseImgUrl + item">
            </v-avatar>
          </div>
        </div>
        <v-btn
          slot="btn"
          text
          class="subtitle"
          @click="closeDialog"
        >
          {{ $t('global.cancel') }}
        </v-btn>
        <v-btn
          slot="btn"
          text
          class="primary--text"
          @click="setUserAvatar"
        >
          {{ $t('global.save') }}
        </v-btn>
      </Dialog>

      <!-- 使用者資訊 -->
      <v-row class="userInfo avatar-type3">
        <v-col
          cols="12"
          class="text-center"
        >
          <div class="avatar-row">
            <div class="avatar-left">
              <v-col
                class="userInfo__avatar pb-2"
              >
                <!-- Liff 模式 (頭像)-->
                <template v-if="isLiffMode">
                  <v-avatar
                    size="83"
                    class="mt-2"
                    :style="avatarBorderClr"
                  >
                    <img :src="lineAvatar">
                  </v-avatar>
                </template>

                <!-- 一般模式 (頭像)-->
                <template v-else>
                  <v-avatar
                    size="55"
                    :style="avatarBorderClr"
                    @click="preChangeUserAvatar"
                  >
                    <img
                      v-if="userBaseInfo.avatar"
                      :src="baseImgUrl + userBaseInfo.avatar"
                      alt="userBaseInfo.username"
                    >
                  </v-avatar>
                </template>
              </v-col>

              <!-- 如果未登入 -->
              <template v-if="!isLogin">
                <v-col
                  class="userInfo__name subtitle-2"
                >
                  <!-- 請先登入文字 -->
                  <span>{{ $t('flashMessage.loginFirst') }}</span>
                </v-col>
              </template>

              <!-- 如果已經登入 -->
              <template v-else>
                <!-- 使用者名稱 -->
                <v-col
                  class="userInfo__name subtitle-2"
                >
                  <!-- Liff 模式 -->
                  <template v-if="isLiffMode">
                    <span>{{ lineDisplayName }}</span>
                    <span>({{ userData.member.account }})</span>
                  </template>

                  <!-- 一般模式 -->
                  <template v-else>
                    <span>{{ userData.member.account }}</span>
                  </template>

                  <Icon
                    v-if="showVip"
                    class="user-vip-diamond"
                    data="@icon/vip/diamond.svg"
                    width="16px"
                    height="13px"
                    color="#01D0FB #DFF6FD #99E6FC #F2FBFF #DFF6FD #C8EFFE #99E6FC #62DBFB"
                  />
                </v-col>
              </template>
            </div>
            <div class="avatar-right">
              <router-link
                :to="isLogin?'/service':'/login'"
                class="text-decoration-none"
              >
                <v-btn
                  class="round-btn"
                  :style="gradientBg"
                >
                  <span class="font-weight-bold subtitle-2">
                    {{ isLogin? $t('service.customerService'): $t('subHeader.login') }}
                  </span>
                </v-btn>
              </router-link>
            </div>
          </div>
        </v-col>
      </v-row>

      <!-- 錢包+vip區塊  -->
      <v-row class="wallet-vip-block">
        <v-col cols="12">
          <!-- 錢包金額+按鈕連結 -->
          <v-col
            cols="12"
            class="wallet-block"
            :style="{'padding-bottom': isLogin && showVip ? '0' : '12px'}"
          >
            <!-- 錢包金額顯示 -->
            <div class="wallet-amount">
              <!-- 中心錢包 -->
              <div
                cols="12"
                class="text-left font-weight-bold caption grey-text"
              >
                <Icon
                  data="@icon/user/walletItem/wallet.svg"
                  width="18px"
                  height="18px"
                  class="secondary--text"
                />
                <span class="grey-text">
                  {{ $t('myCenter.mainWallet') }}
                </span>
                <v-btn
                  v-if="isLogin"
                  slot="btn"
                  icon
                  class="refresh-btn"
                  @click="getUserBalance"
                >
                  <Icon
                    data="@icon/user/walletItem/refresh.svg"
                    width="16px"
                    height="16px"
                    class="btnText--text"
                  />
                </v-btn>
              </div>
              <div
                cols="12"
                class="btnText--text p-l-20"
              >
                <span>
                  {{ currencySymbol_ }}
                </span>
                <span class="user-wallet">{{ isLogin ? player.balance : '--' }}</span>
              </div>

              <!-- liff 免費錢包 -->
              <template v-if="isLiffMode && isLogin">
                <div
                  cols="12"
                  class="text-left font-weight-bold caption grey-text"
                >
                  <Icon
                    data="@icon/user/walletItem/wallet.svg"
                    width="18px"
                    height="18px"
                    class="secondary--text"
                  />
                  {{ free.name }}
                </div>
                <div
                  cols="12"
                  class="btnText--text p-l-20"
                >
                  <span>
                    {{ currencySymbol_ }}
                  </span>
                  <span class="user-wallet">{{ free.balance }}</span>
                </div>
              </template>
            </div>

            <!-- 快捷功能(取款/轉換/存款) -->
            <div class="wallet-buttons">
              <v-col
                v-if="!isLiffMode"
                cols="3"
                class="text-center d-flex justify-center align-center pa-0"
              >
                <!-- 取款 -->
                <v-btn
                  to="user/withdrawal"
                  block
                  text
                  height="56"
                  class="rectangle-btn caption font-weight-bold"
                  :style="gradientBg"
                >
                  {{ $t('global.withdraw') }}
                </v-btn>
              </v-col>

              <!-- Line Liff 模式只會顯示 [轉換]  -->
              <v-col
                cols="3"
                class="text-center d-flex justify-center align-center pa-0"
              >
                <!-- 轉換 -->
                <v-btn
                  to="user/transfer"
                  block
                  text
                  height="56"
                  class="rectangle-btn caption font-weight-bold"
                  :style="gradientBg"
                >
                  {{ $t('global.transferBtn') }}
                </v-btn>
              </v-col>

              <v-col
                v-if="!isLiffMode"
                cols="3"
                class="text-center d-flex justify-center align-center pa-0"
              >
                <!-- 存款 -->
                <v-btn

                  block
                  text
                  height="56"
                  class="rectangle-btn caption font-weight-bold"
                  :style="gradientBg"
                  @click="goPageFunc('user/deposit')"
                >
                  {{ $t('global.deposit') }}
                </v-btn>
              </v-col>
            </div>
          </v-col>

          <!-- vip 長條等級圖 -->
          <v-col
            v-if="isLogin && showVip"
            cols="12"
            class="userInfo__progress vip d-flex align-center"
          >
            <v-img
              v-if="nowVipData.logo"
              class="vip__now"
              :src="baseImgUrl + nowVipData.logo"
              max-height="28"
              max-width="52"
            />
            <!-- 進度條 -->
            <v-progress-linear
              class="mx-1 vip__progress"
              rounded
              :value="vipProgress.progress"
              color="primary"
              height="9"
            />

            <!-- 下一等級 icon -->
            <v-img
              v-if="nextVipData"
              class="vip__next"
              :src="baseImgUrl + nextVipData.logo"
              max-height="28"
              max-width="52"
            />
          </v-col>

          <!-- vip 晉級說明文字 -->
          <v-col
            v-if="isLogin && showVip"
            cols="12 text-left subtitle-2 vip-text"
          >
            <div class="vip__description caption grey-text">
              <!-- vip等級 -->
              <!-- <span>
                {{
                  $t('myCenter.myVipLevel') +
                    ': ' +
                    (nowVipData.name ? nowVipData.name : '--')
                }}
                <br>
              </span> -->

              <span v-if="nextVipData">
                {{
                  `${$t('myCenter.nextAmt')} : ${upgradeNeedTurnover}`
                }}<br>
                {{
                  `${$t('myCenter.nextDeposit')} : ${upgradeNeedDeposit}`
                }}
              </span>
            </div>
            <div class="vip-more caption grey-text">
              <router-link
                to="/user/vip"
                class="grey-text"
              >
                {{
                  $t('myCenter.vipMore')+' >'
                }}
              </router-link>
            </div>
          </v-col>
        </v-col>
      </v-row>
      <!-- 掃碼登入 -->
      <!-- <v-row v-if="layout === 'template2'">
        <v-col>
          <div
            class="shadow-round-btn dark-bg"
            @click="goQrCode"
          >
            <span class="caption font-weight-medium">
              {{ $t('game.scanLogin') }}
            </span>
            <Icon
              data="@icon/scan.svg"
              width="20"
              height="20"
              color="white"
            />
          </div>
        </v-col>
      </v-row> -->

      <!-- 其它頁面導引 -->
      <v-row>
        <!-- 方塊區塊連結 -->
        <v-col cols="12">
          <v-row
            class="links-block shadow-round-container"
          >
            <!-- 福利中心 盡享優惠文字標頭區塊 -->
            <v-col
              cols="12"
              class="links-block-title"
            >
              <div class="links-block-title-text font-weight-bold">
                {{ `${$t('myCenter.benefitCenter')} ${$t('myCenter.benefitJoy')}` }}
              </div>
              <div class="links-block-title-btn">
                <v-btn
                  to="/bonus"
                  block
                  text
                  height="34"
                  :style="gradientBg"
                >
                  {{ `${$t('myCenter.getProfit')} >` }}
                </v-btn>
              </div>
            </v-col>
            <!-- 個人資料 -->
            <v-col
              :cols="itemsWidth"
              class="text-center px-1 py-1"
            >
              <v-btn
                to="user/personal"
                block
                height="80"
              >
                <div class="d-flex flex-column align-center justify-center link-text">
                  <Icon
                    data="@icon/user/mainControl/personal2.svg"
                    width="34px"
                    height="34px"
                    class="secondary--text"
                  />
                  <span class="caption font-weight-bold">
                    {{ $t('myCenter.personalInformation') }}
                  </span>
                </div>
              </v-btn>
            </v-col>
            <!-- 卡片 -->
            <!-- [非 LINE LIFF 模式] 或 [後台設定[提款]為直接攤平]，隱藏銀行卡 -->
            <v-col
              v-if="showCard"
              :cols="itemsWidth"
              class="text-center px-1 py-1"
            >
              <v-btn
                block
                height="80"
                @click="goPageFunc('user/card')"
              >
                <div class="d-flex flex-column align-center justify-center link-text">
                  <Icon
                    data="@icon/user/mainControl/card.svg"
                    width="34px"
                    height="34px"
                    class="secondary--text"
                  />
                  <span class="caption font-weight-bold">
                    {{ $t('global.cards') }}
                  </span>
                </div>
              </v-btn>
            </v-col>
            <!-- 交易紀錄 -->
            <v-col
              :cols="itemsWidth"
              class="text-center px-1 py-1"
            >
              <v-btn
                block
                height="80"
                @click="goPageFunc('user/trade')"
              >
                <div class="d-flex flex-column align-center justify-center link-text">
                  <Icon
                    data="@icon/user/mainControl/transfer2.svg"
                    width="34px"
                    height="34px"
                    class="secondary--text"
                  />
                  <span class="caption font-weight-bold">
                    {{ $t('global.transactionRecord') }}
                  </span>
                </div>
              </v-btn>
            </v-col>
            <!-- 消息中心 -->
            <v-col
              v-if="!isLiffMode"
              :cols="itemsWidth"
              class="text-center px-1 py-1"
            >
              <v-btn
                to="user/news"
                block
                height="80"
              >
                <div class="h-100 d-flex flex-column align-center justify-center link-text">
                  <Icon
                    data="@icon/user/mainControl/msg2.svg"
                    width="34px"
                    height="34px"
                    class="secondary--text"
                  />
                  <span class="caption font-weight-bold">
                    {{ $t('myCenter.news') }}
                  </span>
                </div>
              </v-btn>
            </v-col>
            <!-- 推薦禮金 -->
            <v-col
              :cols="itemsWidth"
              class="text-center px-1 py-1"
            >
              <v-btn
                to="recommend"
                block
                height="80"
              >
                <div class="h-100 d-flex flex-column align-center justify-center link-text">
                  <Icon
                    data="@icon/user/mainControl/vip2.svg"
                    width="34px"
                    height="34px"
                    class="secondary--text"
                  />
                  <span class="caption font-weight-bold">
                    {{ $t('myCenter.recommendBonus') }}
                  </span>
                </div>
              </v-btn>
            </v-col>

            <!-- 投注紀錄 -->
            <v-col
              :cols="itemsWidth"
              class="text-center px-1 py-1"
            >
              <v-btn
                to="user/wager"
                block
                height="80"
              >
                <div class="h-100 d-flex flex-column align-center justify-center link-text">
                  <Icon
                    data="@icon/user/mainControl/wager2.svg"
                    width="34px"
                    height="34px"
                    class="secondary--text"
                  />
                  <span class="caption font-weight-bold">
                    {{ $t('global.wagerRecord') }}
                  </span>
                </div>
              </v-btn>
            </v-col>

            <!-- 兌獎紀錄 -->
            <v-col
              v-if="!isLiffMode"
              :cols="itemsWidth"
              class="text-center px-1 py-1"
            >
              <v-btn
                to="user/prize"
                block
                height="80"
              >
                <div class="h-100 d-flex flex-column align-center justify-center link-text">
                  <Icon
                    data="@icon/user/mainControl/gift2.svg"
                    width="34px"
                    height="34px"
                    class="secondary--text"
                  />
                  <span class="caption font-weight-bold">
                    {{ $t('global.prizeRecord') }}
                  </span>
                </div>
              </v-btn>
            </v-col>

            <!-- 加入我們 -->
            <v-col
              :cols="itemsWidth"
              class="text-center px-1 py-1"
            >
              <v-btn
                to="agent"
                block
                height="80"
              >
                <div class="h-100 d-flex flex-column align-center justify-center link-text">
                  <Icon
                    data="@icon/user/mainControl/help2.svg"
                    width="34px"
                    height="34px"
                    class="secondary--text"
                  />
                  <span class="caption font-weight-bold">
                    {{ $t('myCenter.joinUs') }}
                  </span>
                </div>
              </v-btn>
            </v-col>
            <!-- 常見問題 -->
            <!-- [LINE LIFF 模式] 或 [後台設定[提款]為直接攤平]，顯示常見問題 -->
            <v-col
              v-if="isLiffMode || withdrawalIsFlatten"
              :cols="itemsWidth"
              class="text-center px-1 py-1"
            >
              <v-btn
                to="faq/problem"
                block
                height="80"
              >
                <div class="h-100 d-flex flex-column align-center justify-center link-text">
                  <Icon
                    data="@icon/user/mainControl/help.svg"
                    width="34px"
                    height="34px"
                    class="secondary--text"
                  />
                  <span class="caption font-weight-bold">
                    {{ $t('myCenter.commonProblem') }}
                  </span>
                </div>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>

        <!-- 頁面連結 -->
        <v-col
          cols="12"
        >
          <!-- 切換語系 (liff 不顯示) -->
          <!-- <div
            v-show="!isLiffMode"
            class="text-decoration-none title--text pageLink"
          >
            <v-divider class="pb-3 divider" />
            <div
              class="d-flex mb-2 px-2"
              @click="language.status = !language.status"
            >
              <span class="caption font-weight-medium">
                {{ $t('myCenter.language') }}
              </span>
              <v-spacer />
              <v-icon right>
                mdi-chevron-right
              </v-icon>
            </div>
          </div> -->

          <!-- 登出按鈕 -->
          <div
            v-if="isLogin"
            class="shadow-round-btn"
            @click="userLogout"
          >
            <span class="caption font-weight-bold">
              {{ $t('subHeader.logout') }}
            </span>
            <Icon
              data="@icon/user/mainControl/logout.svg"
              width="20px"
              height="20px"
              class="secondary--text"
            />
          </div>
        </v-col>
      </v-row>

      <!-- 語系 popup -->
      <v-bottom-sheet
        v-model="language.status"
        :fullscreen="true"
      >
        <v-sheet
          class="text-center"
          height="200px"
        >
          <v-card
            class="mx-auto bg"
            height="100vh"
          >
            <v-card-text class="pa-0">
              <!-- 關閉切換語系 -->
              <v-toolbar class="bg">
                <v-btn
                  icon
                  @click="language.status = !language.status"
                >
                  <Icon
                    data="@icon/toolbar/arrow_left.svg"
                    width="16px"
                    height="16px"
                    class="link-text"
                  />
                </v-btn>
                <v-toolbar-title>
                  {{ $t('myCenter.language') }}
                </v-toolbar-title>
                <v-spacer />
              </v-toolbar>

              <!-- 搜尋 -->
              <v-list
                three-line
                subheader
                class="bg"
              >
                <div class="search-area">
                  <v-text-field
                    id="searchInput"
                    v-model.trim="language.searchKeyword"
                    autofocus
                    class="mx-4 mt-2 inputBg"
                    hide-details
                    :placeholder="$t('game.searchGame')"
                    rounded
                    autocomplete="off"
                    dense
                  >
                    <!-- 放大鏡 -->
                    <div
                      slot="prepend-inner"
                      class="mt-0"
                    >
                      <v-btn
                        slot="btn"
                        icon
                      >
                        <Icon
                          data="@icon/search.svg"
                          width="24"
                          height="24"
                          class="white--text"
                        />
                      </v-btn>
                    </div>
                  </v-text-field>
                </div>
              </v-list>

              <v-list
                v-for="lang in language.resultLangs"
                :key="lang._id"
                class="bg text-left px-4 py-0"
              >
                <!-- 切換本地語系 -->
                <v-list-item
                  :class="[{'primary rounded-xl': activeLang === lang.code}]"
                  @click="settingClientLang(lang.code)"
                >
                  <!-- ICON -->
                  <div class="local-name mr-3 title--text">
                    <v-img
                      :src="baseImgUrl + lang.icon"
                      max-width="20"
                    />
                  </div>
                  <!-- 默認名稱 -->
                  <div class="default-name title--text">
                    {{ lang.default_name }}
                  </div>
                </v-list-item>
                <v-divider class="divider" />
              </v-list>
            </v-card-text>
          </v-card>
        </v-sheet>
      </v-bottom-sheet>
    </v-container>
  </div>
</template>

<script>
import indexMixins from '../mixins/index'

export default {
  name: 'IndexTemplate1',
  mixins: [indexMixins],
}
</script>

<style lang="scss" scoped>

</style>
