var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "pa-0 px-4 ma-0 pb-5 header" },
        [
          _c(
            "v-row",
            { staticClass: "userInfo" },
            [
              _c(
                "v-col",
                { staticClass: "text-center", attrs: { cols: "12" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "userInfo__avatar pb-2 mt-2",
                          attrs: { cols: "12" },
                        },
                        [
                          _vm.isLiffMode
                            ? [
                                _c(
                                  "v-avatar",
                                  {
                                    staticClass: "mt-2",
                                    style: _vm.avatarBorderClr,
                                    attrs: { size: "83" },
                                  },
                                  [
                                    _c("img", {
                                      attrs: { src: _vm.lineAvatar },
                                    }),
                                  ]
                                ),
                              ]
                            : [
                                _vm.userBaseInfo.avatar
                                  ? _c(
                                      "v-avatar",
                                      {
                                        staticClass: "mt-2",
                                        attrs: { size: "83" },
                                        on: { click: _vm.preChangeUserAvatar },
                                      },
                                      [
                                        _vm.userBaseInfo.avatar
                                          ? _c("img", {
                                              attrs: {
                                                src:
                                                  _vm.baseImgUrl +
                                                  _vm.userBaseInfo.avatar,
                                                alt: "userBaseInfo.username",
                                              },
                                            })
                                          : _vm._e(),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                        ],
                        2
                      ),
                      !_vm.isLogin
                        ? [
                            _c(
                              "v-col",
                              { staticClass: "pt-2" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mb-4",
                                    attrs: {
                                      to: "/login",
                                      outlined: "",
                                      color: "primary",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "subtitle-2 primary--text",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("flashMessage.loginFirst")
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        : [
                            _c(
                              "v-col",
                              {
                                staticClass: "userInfo__name py-0 subtitle-2",
                                attrs: { cols: "12" },
                              },
                              [
                                _vm.isLiffMode
                                  ? [
                                      _c("span", { staticClass: "mr-1" }, [
                                        _vm._v(_vm._s(_vm.lineDisplayName)),
                                      ]),
                                      _c("span", { staticClass: "mr-1" }, [
                                        _vm._v(
                                          "(" +
                                            _vm._s(
                                              _vm.userData.member.account
                                            ) +
                                            ")"
                                        ),
                                      ]),
                                    ]
                                  : [
                                      _c(
                                        "span",
                                        { staticClass: "mr-1 secondary--text" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.userData.member.account)
                                          ),
                                        ]
                                      ),
                                    ],
                              ],
                              2
                            ),
                          ],
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "walletArea" },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "px-4 pb-3" },
                    [
                      _vm.isLogin
                        ? _c(
                            "v-col",
                            {
                              staticClass: "d-flex justify-center align-center",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "currencySymbol white rounded-circle d-flex justify-center align-center",
                                },
                                [
                                  _c("span", { staticClass: "black--text" }, [
                                    _vm._v(
                                      " " + _vm._s(_vm.currencySymbol_) + " "
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "ml-1 mr-3 caption white--text",
                                },
                                [_vm._v(_vm._s(_vm.player.name))]
                              ),
                              _c(
                                "span",
                                { staticClass: "ml-1 mr-3 white--text" },
                                [_vm._v(_vm._s(_vm.player.balance))]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.isLiffMode
                        ? _c(
                            "v-col",
                            {
                              staticClass:
                                "d-flex justify-center align-center pt-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "currencySymbol white rounded-circle d-flex justify-center align-center",
                                },
                                [
                                  _c("span", { staticClass: "black--text" }, [
                                    _vm._v(
                                      " " + _vm._s(_vm.currencySymbol_) + " "
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "ml-1 mr-3 caption white--text",
                                },
                                [_vm._v(_vm._s(_vm.free.name))]
                              ),
                              _c(
                                "span",
                                { staticClass: "ml-1 mr-3 white--text" },
                                [_vm._v(_vm._s(_vm.free.balance))]
                              ),
                            ]
                          )
                        : _vm._e(),
                      !_vm.isLiffMode
                        ? _c(
                            "v-col",
                            {
                              staticClass:
                                "text-center d-flex justify-center align-center pa-0",
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "px-0 secondary",
                                  attrs: {
                                    block: "",
                                    to: "user/withdrawal",
                                    text: "",
                                    height: "32",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-center justify-center primary--text caption font-weight-bold",
                                    },
                                    [
                                      _c("Icon", {
                                        staticClass: "mr-1",
                                        class: [
                                          _vm.style === "darkGD"
                                            ? "primary--text"
                                            : "",
                                        ],
                                        attrs: {
                                          data: require("@icon/user/walletItem/withdrawal.svg"),
                                          width: "23px",
                                          height: "20px",
                                        },
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("global.withdraw")) +
                                          " "
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "text-center d-flex justify-center align-center pa-0 mx-1",
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "secondary",
                              attrs: {
                                block: "",
                                to: "user/transfer",
                                text: "",
                                height: "32",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex align-center justify-center primary--text caption font-weight-bold",
                                },
                                [
                                  _c("Icon", {
                                    staticClass: "mr-1",
                                    class: [
                                      _vm.style === "darkGD"
                                        ? "primary--text"
                                        : "",
                                    ],
                                    attrs: {
                                      data: require("@icon/user/walletItem/transfer.svg"),
                                      width: "23px",
                                      height: "20px",
                                    },
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("global.transferBtn")) +
                                      " "
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      !_vm.isLiffMode
                        ? _c(
                            "v-col",
                            {
                              staticClass:
                                "text-center d-flex justify-center align-center pa-0",
                              class: [{ "mt-2": _vm.activeLang === "vi-VN" }],
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "px-0 primary",
                                  attrs: { block: "", text: "", height: "32" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.goPageFunc("user/deposit")
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-center justify-center secondary--text caption font-weight-bold",
                                    },
                                    [
                                      _c("Icon", {
                                        staticClass: "mr-1",
                                        class: [
                                          _vm.style === "darkGD"
                                            ? "primary--text"
                                            : "",
                                        ],
                                        attrs: {
                                          data: require("@icon/user/walletItem/deposit.svg"),
                                          width: "23px",
                                          height: "20px",
                                        },
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("global.deposit")) +
                                          " "
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-container",
        { class: _vm.currentDevice === "ios" ? "pb-72" : "pb-56" },
        [
          _c(
            "Dialog",
            { attrs: { dialog: _vm.dialog.status, width: 320, height: 302 } },
            [
              _c(
                "span",
                {
                  staticClass: "text-h6",
                  attrs: { slot: "title" },
                  slot: "title",
                },
                [_vm._v(" " + _vm._s(_vm.$t("myCenter.myAvatar")) + " ")]
              ),
              _c(
                "div",
                {
                  staticClass: "d-flex flex-column align-center",
                  attrs: { slot: "text" },
                  slot: "text",
                },
                [
                  _c(
                    "v-avatar",
                    {
                      staticClass: "mt-2",
                      style: _vm.avatarBorderClr,
                      attrs: { size: "83", color: "primary" },
                    },
                    [
                      _vm.userBaseInfo.avatar || _vm.dialog.form.avatar
                        ? _c("img", {
                            attrs: {
                              src:
                                _vm.baseImgUrl + _vm.dialog.form.avatar ||
                                _vm.baseImgUrl + _vm.userBaseInfo.avatar,
                              alt: "userBaseInfo.username",
                            },
                          })
                        : _vm._e(),
                    ]
                  ),
                  _c("span", { staticClass: "caption title--text mt-1" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("myCenter.avatarPreview")) + " "
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-space-between w-100 mt-1 px-6",
                    },
                    _vm._l(_vm.dialog.avatarList, function (item) {
                      return _c(
                        "v-avatar",
                        {
                          key: item,
                          staticClass: "mt-2",
                          style:
                            _vm.dialog.form.avatar === item
                              ? _vm.avatarBorderClr
                              : "",
                          attrs: { size: "48", color: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.dialog.form.avatar = item
                            },
                          },
                        },
                        [_c("img", { attrs: { src: _vm.baseImgUrl + item } })]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "subtitle",
                  attrs: { slot: "btn", text: "" },
                  on: { click: _vm.closeDialog },
                  slot: "btn",
                },
                [_vm._v(" " + _vm._s(_vm.$t("global.cancel")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "primary--text",
                  attrs: { slot: "btn", text: "" },
                  on: { click: _vm.setUserAvatar },
                  slot: "btn",
                },
                [_vm._v(" " + _vm._s(_vm.$t("global.save")) + " ")]
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "px-4 py-3 navBar" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center px-1 py-1",
                          attrs: { cols: _vm.isLiffMode ? 6 : _vm.itemsWidth },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                block: "",
                                depressed: "",
                                height: "80",
                                color: "card1",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.goPageFunc("user/trade")
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-column align-center justify-center icon--text",
                                },
                                [
                                  _c("Icon", {
                                    class: [
                                      _vm.theme === "dark"
                                        ? "secondary--text"
                                        : "primary--text",
                                    ],
                                    attrs: {
                                      data: require("@icon/user/mainControl/transfer.svg"),
                                      width: "48px",
                                      height: "48px",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "caption font-weight-bold" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("global.transactionRecord")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center px-1 py-1 center-btn",
                          attrs: { cols: _vm.isLiffMode ? 6 : _vm.itemsWidth },
                        },
                        [
                          _c("v-divider", {
                            staticClass:
                              "pb-3 icon--text p-absolute z-index-1 left-0 top-0",
                            attrs: { vertical: "" },
                          }),
                          !_vm.isLiffMode
                            ? _c("v-divider", {
                                staticClass:
                                  "pb-3 icon--text p-absolute z-index-1 right-0 top-0",
                                attrs: { vertical: "" },
                              })
                            : _vm._e(),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                to: "user/wager",
                                block: "",
                                depressed: "",
                                height: "80",
                                color: "card1",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "h-100 d-flex flex-column align-center justify-center icon--text",
                                },
                                [
                                  _c("Icon", {
                                    staticClass: "pa-2",
                                    class: [
                                      _vm.theme === "dark"
                                        ? "secondary--text"
                                        : "primary--text",
                                    ],
                                    attrs: {
                                      data: require("@icon/user/mainControl/wager.svg"),
                                      width: "48px",
                                      height: "48px",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "caption font-weight-bold" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("global.wagerRecord")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      !_vm.isLiffMode
                        ? _c(
                            "v-col",
                            {
                              staticClass: "text-center px-1 py-1",
                              attrs: { cols: _vm.itemsWidth },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    to: "user/prize",
                                    block: "",
                                    depressed: "",
                                    height: "80",
                                    color: "card1",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "h-100 d-flex flex-column align-center justify-center icon--text",
                                    },
                                    [
                                      _c("Icon", {
                                        class: [
                                          _vm.theme === "dark"
                                            ? "secondary--text"
                                            : "primary--text",
                                        ],
                                        attrs: {
                                          data: require("@icon/user/mainControl/gift.svg"),
                                          width: "48",
                                          height: "48",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "caption font-weight-bold",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("global.prizeRecord")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              !_vm.isLiffMode
                ? _c(
                    "v-col",
                    { staticClass: "mt-4 pt-1 px-4", attrs: { cols: "12" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "rounded-lg px-4",
                          class: _vm.theme === "dark" ? "black" : "white",
                        },
                        [
                          _vm._l(_vm.pageItem, function (item, index) {
                            return _c(
                              "router-link",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.noShowRouterLink.includes(
                                      Object.keys(item)[0]
                                    ),
                                    expression:
                                      "!noShowRouterLink.includes(Object.keys(item)[0])",
                                  },
                                ],
                                key: index,
                                staticClass:
                                  "text-decoration-none title--text pageLink",
                                attrs: { to: `/${Object.keys(item)[0]}` },
                              },
                              [
                                _c("v-divider", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: index !== 0,
                                      expression: "index !== 0",
                                    },
                                  ],
                                  staticClass: "pb-3 icon--text",
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex mb-2 px-2",
                                    class: [{ "pt-3": index === 0 }],
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "caption font-weight-medium",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(Object.values(item)[0]) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c("v-spacer"),
                                    _c("v-icon", { attrs: { right: "" } }, [
                                      _vm._v(" mdi-chevron-right "),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "text-decoration-none title--text pageLink",
                            },
                            [
                              _c("v-divider", {
                                staticClass: "pb-3 icon--text",
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex pb-3 px-2",
                                  on: {
                                    click: function ($event) {
                                      _vm.language.status = !_vm.language.status
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "caption font-weight-medium",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("myCenter.language")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("v-spacer"),
                                  _c("v-icon", { attrs: { right: "" } }, [
                                    _vm._v(" mdi-chevron-right "),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.isLogin
                ? _c(
                    "v-col",
                    {
                      staticClass: "px-4 mb-4 pt-0 mt-4",
                      attrs: { cols: "12" },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { block: "", height: "40", color: "navBar" },
                          on: { click: _vm.userLogout },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "subtitle-2 font-weight-medium",
                              class:
                                _vm.theme === "dark"
                                  ? "secondary--text"
                                  : "primary--text",
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("subHeader.logout")) + " "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-bottom-sheet",
            {
              attrs: { fullscreen: true },
              model: {
                value: _vm.language.status,
                callback: function ($$v) {
                  _vm.$set(_vm.language, "status", $$v)
                },
                expression: "language.status",
              },
            },
            [
              _c(
                "v-sheet",
                { staticClass: "text-center", attrs: { height: "200px" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "mx-auto bg", attrs: { height: "100vh" } },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "pa-0" },
                        [
                          _c(
                            "v-toolbar",
                            { staticClass: "primary" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "" },
                                  on: {
                                    click: function ($event) {
                                      _vm.language.status = !_vm.language.status
                                    },
                                  },
                                },
                                [
                                  _c("Icon", {
                                    staticClass: "white--text",
                                    attrs: {
                                      data: require("@icon/toolbar/toolbar_goback_arrow.svg"),
                                      width: "16px",
                                      height: "16px",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-toolbar-title",
                                { staticClass: "white--text" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("myCenter.language")) +
                                      " "
                                  ),
                                ]
                              ),
                              _c("v-spacer"),
                            ],
                            1
                          ),
                          _c(
                            "v-list",
                            {
                              staticClass: "bg",
                              attrs: { "three-line": "", subheader: "" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "search-area" },
                                [
                                  _c(
                                    "v-text-field",
                                    {
                                      staticClass: "mx-4 my-3 card1 pt-0",
                                      attrs: {
                                        id: "searchInput",
                                        autofocus: "",
                                        "hide-details": "",
                                        placeholder: _vm.$t("game.searchGame"),
                                        rounded: "",
                                        height: "48",
                                        autocomplete: "off",
                                      },
                                      model: {
                                        value: _vm.language.searchKeyword,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.language,
                                            "searchKeyword",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "language.searchKeyword",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "mt-1",
                                          attrs: { slot: "prepend-inner" },
                                          slot: "prepend-inner",
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { slot: "btn", icon: "" },
                                              slot: "btn",
                                            },
                                            [
                                              _c("Icon", {
                                                staticClass: "title--text",
                                                attrs: {
                                                  data: require("@icon/search.svg"),
                                                  width: "24",
                                                  height: "24",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._l(
                            _vm.language.resultLangs,
                            function (lang, index) {
                              return _c(
                                "v-list",
                                {
                                  key: lang._id,
                                  staticClass: "bg text-left px-4 py-0",
                                },
                                [
                                  _c(
                                    "v-list-item",
                                    {
                                      staticClass: "px-0",
                                      class: [
                                        {
                                          "primary rounded":
                                            _vm.activeLang === lang.code,
                                        },
                                      ],
                                      on: {
                                        click: function ($event) {
                                          return _vm.settingClientLang(
                                            lang.code
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "local-name mr-3" },
                                        [
                                          false
                                            ? _c("v-img", {
                                                attrs: {
                                                  src:
                                                    _vm.baseImgUrl + lang.icon,
                                                  "max-width": "20",
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "default-name",
                                          class: [
                                            _vm.activeLang === lang.code
                                              ? "white--text"
                                              : "title--text",
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(lang.default_name) +
                                              " "
                                          ),
                                          _c("span", { staticClass: "ml-2" }, [
                                            _vm._v(
                                              " " + _vm._s(lang.remark) + " "
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                  index !== _vm.language.resultLangs.length - 1
                                    ? _c("v-divider")
                                    : _vm._e(),
                                ],
                                1
                              )
                            }
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }